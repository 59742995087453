import { EyeIcon, XIcon } from '@heroicons/react/solid';
import { Modal } from '@mui/material';
import React, { useEffect, useState } from 'react'
import Moment from 'react-moment';
import { Link, useParams, useNavigate } from "react-router-dom";
import { getUserLeaveData } from '../../api';
import Layout from '../../components/layout/Layout'
import spinner from '../../assets/images/spinner.gif'
import ZoomImage from '../../components/ZoomImage';

function LeaveDetail() {
    const { id } = useParams()
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false)
    const [leaveData, setLeaveData] = useState([])
    const [userId, setUserId] = useState('')
    const [leaveDocument, setLeaveDocument] = useState([])
    const [singleLeaveData, setSingleLeaveData] = useState({})
    const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false)
    useEffect(() => {
        handleGetUserLeaveData()
    }, [])
    const handleGetUserLeaveData = () => {
        setIsLoading(true)
        getUserLeaveData(id)
            .then((res) => {
                if (res.status === 200) {
                    setLeaveData(res.data.data)
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                alert('something went wrong please refresh')
                setIsLoading(false)
            })
    }
    const handleFetchUserDocuments = (id) => {
        setUserId(id)
        setSingleLeaveData(leaveData.find(data => data.id === id))
        console.log(leaveData.find(data => data.id === id))
        setIsOpenDocumentModal(true)
    }
    return (
        <Layout>
            {isLoading ? <div className='w-12 h-12 mx-auto mt-10'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className="container px-4">
                <div className=" text-start">
                    <button onClick={() => navigate(-1)} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Back</button>
                </div>
                {leaveData.length <= 0 ? <div className='text-center'><p className=' text-gray-700 text-3xl'>No Leaves To Show</p></div> : <div class="max-w-full overflow-x-auto mt-8">
                    <table class="table-auto w-full">
                        <thead>
                            <tr class=" bg-[color:var(--color1)] text-center">
                                <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.no</th>
                                <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Dates</th>
                                <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Reason</th>
                                <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Leave Type</th>
                                <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Status</th>
                                <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Document</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leaveData?.map((data, idx) => {
                                return <tr key={idx}>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{idx + 1}</td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                        <small className='px-2 py-1 rounded bg-gray-500 text-white block'>From : <Moment unix format='DD/MM/YYYY'>{data.from_date}</Moment></small>
                                        {(data.to_date !== '0') &&  
                                        <small className='px-2 py-1 rounded bg-gray-500 text-white block mt-2'>To : <Moment unix format='DD/MM/YYYY'>{data.to_date}</Moment></small>}
                                    </td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{data.reason}</td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{data.leave_type}</td>
                                    <td className=' text-center border-b bg-[#F3F6FF]'>
                                        {data.leave_status === '1' ? <span className='bg-green-700 text-green-200 px-2 py-1 rounded-full text-xs'>Approved</span>
                                            : data.leave_status === '2' ? <span className='bg-yellow-600 text-yellow-200 px-2 py-1 rounded-full text-xs'>Pending</span>
                                                : <span className='bg-red-700 text-red-200 px-2 py-1 rounded-full text-xs'>Rejected</span>}
                                    </td>
                                    <td class="text-center select-none text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">
                                        {data.file_list ? <div className='flex items-center justify-center w-full'>
                                            <button type='button' onClick={() => handleFetchUserDocuments(data.id)} className='bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center'><EyeIcon className='w-6 h-6' /> <span>View</span></button>
                                        </div> : '---'}
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>}
            </div>}
            <Modal open={isOpenDocumentModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                    <div className='document-wrapper px-4 divide-y'>
                        <div className="document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h3 className=' text-gray-700 text-3xl font-semibold'>Documents</h3>
                                <div className=' w-6 h-6 cursor-pointer' onClick={() => setIsOpenDocumentModal(false)}>
                                    <XIcon className=' text-black w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className="document-body py-4 max-h-96 overflow-auto">
                            {singleLeaveData?.file_list?.length <= 0 ? <div className='text-center my-4'><p className='text-gray-700 text-2xl'>No Document To Show</p></div> : <div className="grid grid-cols-4 gap-4">
                                {singleLeaveData?.file_list?.map((data, idx) => {
                                    const { file_name, document_media_type } = data
                                    return <>
                                    {
                                       document_media_type === 'pdf' ? <a href={file_name} target="_blank" rel="noopener noreferrer" className=' text-center py-2 px-2 rounded bg-gray-400 text-gray-700'>View PDF</a> : <ZoomImage key={idx} className='h-full'>
                                          <img src={file_name} alt="dummy" className='border-2 border-black w-full h-full' />
                                       </ZoomImage>
                                    }
                                 </>
                                })}
                            </div>}
                        </div>
                        <div className="document-foot space-x-4 py-4">
                            <button className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={() => setIsOpenDocumentModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

export default LeaveDetail