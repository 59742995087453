import { LogoutIcon, MenuIcon, UserIcon } from '@heroicons/react/solid'
import React, { useEffect, useState } from 'react'
import profile from '../assets/images/profile.jpg'

import "./header.css";
import Dropdown from './dropdown/Dropdown';
import { useNavigate } from "react-router-dom";
import Cookies from 'universal-cookie';






const cookies = new Cookies
function Header({ onClick }) {
    const [isShowDropDown, setIsShowDropDown] = useState(false)
    const navigate = useNavigate();

    const handleLogout = () => {
        cookies.remove("_am_token", { path: '/' })
        cookies.remove("_am_role", { path: '/' })
        cookies.remove("_am_name", { path: '/' })
        navigate('/login');
    };
    const [name, setName] = useState('')
    useEffect(() => {
        setName(cookies.get("_am_name"))
    }, [])

    return (
        <>
            <div className=" text-white bg-gray-500 h-16 px-4 shadow sticky top-0 mb-4 z-40">
                <div className=" flex items-center justify-between h-full items">
                    <div className="cursor-pointer toggle" onClick={onClick}>
                        {/* <   MenuIcon className='w-8 text-white ' /> */}
                    </div>
                    <div className="relative">
                        <div onClick={() => setIsShowDropDown(!isShowDropDown)} className=" cursor-pointer flex items-center">
                            <img src={profile} alt="profile" className=' h-10 rounded-full' />
                            <p className=' roboto ml-4 font-semibold'>{name}</p>
                        </div>
                        {
                            isShowDropDown ? <Dropdown show={isShowDropDown} className={' top-14 right-[-4px] bg-[color:var(--color1)] px-4 py-3 rounded'}>
                                <div onClick={handleLogout}>
                                    <ul className=' space-y-4'>
                                        <li className='flex text-white cursor-pointer'>
                                            <LogoutIcon className=' w-4' />
                                            <span className='ml-2'>Logout</span>
                                        </li>
                                        {/* <li className='flex text-gray-700'>
                                        <UserIcon className='w-6'/>
                                        <span>Edit Profile</span>
                                    </li> */}
                                    </ul>
                                </div>
                            </Dropdown> : null
                        }

                    </div>
                </div>
            </div>
        </>
    )
}

export default Header