import './App.css';
import { Route, Routes, BrowserRouter as Router } from "react-router-dom"
import Login from './pages/auth/Login';
import Dashboard from './pages/Dashboard';


import ProtectedRoutes from './Routes/ProtectedRoutes';
import EmployeeDetails from './pages/employee_details';
import AddEmployeeDetails from './pages/employee_details/AddEmployeeDetails';

import LeaveApplication from './pages/leave_application';
import LeaveApplicationForm from './pages/leave_application/LeaveApplicationForm';

import Appointment from './pages/appointment_letter';
import AppointmentLetter from './pages/appointment_letter/AppointmentLetter';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import LeaveApplied from './pages/leaves_applied';
import LeavesApplied from './pages/leaves_applied/LeavesApplied';
import EditEmployee from './pages/employee_details/EditEmployee';
import Department from './pages/department';
import LeaveDetail from './pages/employee_details/LeaveDetail';
import Salary from './pages/Salary';
import Holidays from './pages/holidays';
import Attendance from './pages/attendance';
import ViewAttendance from './pages/attendance/ViewAttendance';
import PageNotFound from './pages/errors/PageNotFound';
import Register from './pages/auth/Register';
import SalaryLogs from './pages/Salary/SalaryLogs';
import ForgotPassword from './pages/auth/ForgotPassword';
import ResetPassword from './pages/auth/ResetPassword';
import { Toaster } from 'react-hot-toast';


const queryClient = new QueryClient()
function App() {
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <Toaster/>
        <Router>
          <Routes>
            <Route path='/login' exact element={<Login />} />
            <Route path='/register-company' exact element={<Register />} />
            <Route path='/' exact element={<ProtectedRoutes accessTo={'all'}><Dashboard /></ProtectedRoutes>} />
            <Route path='/forgot-password' exact element={<ForgotPassword />} />
            <Route path='/reset-password' exact element={<ResetPassword />} />
            <Route path='/attendance' exact element={<ProtectedRoutes accessTo={['company']}><Attendance /></ProtectedRoutes>} />

            <Route path='/employe-details' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><EmployeeDetails /></ProtectedRoutes>} />
            <Route path='/salary' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><Salary /></ProtectedRoutes>} />
            <Route path='/salary/logs' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><SalaryLogs /></ProtectedRoutes>} />
            <Route path='/appointment' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><Appointment /></ProtectedRoutes>} />
            <Route path='/leave-applied' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><LeaveApplied /></ProtectedRoutes>} />
            <Route path='/public-holidays' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><Holidays /></ProtectedRoutes>} />
            <Route path='/add-employe-details' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><AddEmployeeDetails /></ProtectedRoutes>} />
            <Route path='/edit-employe-details/:userType/:id' exact element={<ProtectedRoutes accessTo={['company', 'hr']}><EditEmployee /></ProtectedRoutes>} />

            <Route path='/leave-application' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><LeaveApplication /></ProtectedRoutes>} />
            <Route path='/leaves' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><LeaveDetail /></ProtectedRoutes>} />

            <Route path='/leaves/:id' exact element={<ProtectedRoutes accessTo={'all'}><LeaveDetail /></ProtectedRoutes>} />
            <Route path='/add-leave-details' exact element={<ProtectedRoutes accessTo={['employee', 'consultant']}><LeaveApplicationForm /></ProtectedRoutes>} />
            <Route path='/appointment-letter/:id' exact element={<ProtectedRoutes accessTo={'all'}><AppointmentLetter /></ProtectedRoutes>} />
            <Route path='/open-leave' exact element={<ProtectedRoutes accessTo={'all'}><LeavesApplied /></ProtectedRoutes>} />
            <Route path='/department' exact element={<ProtectedRoutes accessTo={'all'}><Department /></ProtectedRoutes>} />
            <Route path='/view-attendance/:id' exact element={<ProtectedRoutes accessTo={'all'}><ViewAttendance /></ProtectedRoutes>} />
            <Route path='*' exact element={<PageNotFound />} />




          </Routes>
        </Router>
      </QueryClientProvider>
    </>
  );
}

export default App;
