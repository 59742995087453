import React, { useEffect, useState } from 'react'
import Layout from '../../components/layout/Layout'
import useRead from '../../hooks/useRead'
import usePut from '../../hooks/usePut'
import { ArrowLeftIcon, ChevronLeftIcon, PencilAltIcon, XIcon } from '@heroicons/react/solid';
import Calendar from '../../components/Dates/Calendar';
import { getAttendanceData } from '../../api/leaveApplicationForm';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { Alert, Modal, Snackbar } from '@mui/material';
import spinner from '../../assets/images/spinner.gif'
import { useQueryClient } from '@tanstack/react-query';

const tempData = {
    employee_name: '',
    salary: '',
    gst: '',
    professional_tax: '',
    approved_leaves: '',
    tds: '',
    base_salary: '',
    total_days_present: '',
    total_days_absent: '',
    department: '',
    designation: '',
    absent_deduction: '',
    total_pending_dates: '',
    days_present: '',
    days_absent: '',
    public_holidays: '',
    approved_leaves_dates: '',
    month_days: '',
    pending_dates: '',
    prev_month_sal_deduction: '',
    previous_month_absent: '',
    previous_month_days: ''
}

const getDateMonthString = (date) => {
    let tempDate = new Date(date)
    let month = tempDate.getMonth() + 1
    return tempDate.getFullYear() + "-" + month
}

const SalaryLogs = () => {
    const queryClient = useQueryClient()
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    const [editable, setEditable] = useState(false)
    // let generated = searchParams.get("status") === "generated" ? true : false
    let employee = searchParams.get("emp") === "Employee`" ? true : false
    const [currentDatesSection, setCurrentDatesSection] = useState('');
    const [listData, setListData] = useState(tempData)
    const [initialListData, setInitialListData] = useState(null)
    const [filterData, setFilterData] = useState({
        month_and_year: new Date(searchParams.get('date')),
        prev_month_and_year: new Date(searchParams.get('prev_date'))
    })
    // popup messages
    const [createSuccessMessage, setCreateSuccessMessage] = useState(false)
    const [updateSuccessMessage, setUpdateSuccessMessage] = useState(false)

    const { list } = useRead({
        url: 'getPayoutList',
        initialData: {
            month_year: getDateMonthString(searchParams.get('date')),
            user_id: searchParams.get('id')
        },
        onSuccess: (data) => {
            setInitialListData(data?.data[0])
            setListData(data?.data[0])
        }
    })


    const handleChange = (e) => {
        let name = e.target.name
        let value = e.target.value
        setListData({
            ...listData,
            [name]: value
        })
    }

    const [showDates, setShowDates] = useState(false);

    const handleViewDates = (datesSection) => {
        getCalendar.handleAuth.mutate({
            'user_id': searchParams.get('id'),
            'month_year': getDateMonthString(searchParams.get('date')),
        })
        setShowDates(true);
        setCurrentDatesSection(datesSection);
    }
    const handlePrevViewDates = (datesSection) => {
        getCalendar.handleAuth.mutate({
            'user_id': searchParams.get('id'),
            'month_year': getDateMonthString(searchParams.get('date')),
        })
        setShowDates(true);
        setCurrentDatesSection(datesSection);
    }

    const createSalary = usePut({
        url: 'createPayout',
        onSuccess: (data) => {
            setCreateSuccessMessage(true)
            queryClient.invalidateQueries('getPayoutList')
        },
        onError: () => { },
        onSettled: () => {

        }
    })

    const updateSalary = usePut({
        url: 'updatePayout',
        onSuccess: (data) => {
            setUpdateSuccessMessage(true)
            queryClient.invalidateQueries('getPayoutList')
            setEditable(false)
        },
        onError: () => { },
        onSettled: () => { }
    })

    const getCalendar = usePut({
        url: 'getCalenderDetails',
        onSuccess: (data) => {
            let resData = data?.data?.data[0]
            setListData({
                ...listData,
                ...resData
            })
        },
        onError: () => { },
        onSettled: () => { }
    })

    const handleCreateSalary = (e) => {
        e.preventDefault()
        const grossSalary = +listData?.base_salary + +listData?.allowance + +listData?.bonus + +listData?.commission
        const data = {
            'gross_salary': grossSalary,
            'commission': listData['commission'],
            'bonus': listData['bonus'],
            'allowance': listData['allowance'],
            'absent_deduct': listData['absent_deduction'],
            'previous_month_deduct': listData['prev_month_sal_deduction'],
            'no_of_day_present': listData['total_days_present'],
            'no_of_holiday_taken': listData['total_days_absent'],
            'month_year': getDateMonthString(searchParams.get('date')),
            'net_salary': grossSalary - listData['absent_deduction'] - +listData['professional_tax'] - +listData['prev_month_sal_deduction'],
            'user_id': searchParams.get('id'),
            'approved_leaves': listData['approved_leaves'],
            'total_public_holidays': listData['total_public_holidays'],
            'total_week_off': listData['total_week_off'],
            'total_deduction': listData['absent_deduction'] + +listData['professional_tax'] + +listData['prev_month_sal_deduction'],
        }
        createSalary.handleAuth.mutate(data)
    }

    const handleUpdateSalary = (e) => {
        const grossSalary = +listData?.base_salary + +listData?.allowance + +listData?.bonus + +listData?.commission
        const data = {
            'gross_salary': grossSalary,
            'commission': listData['commission'],
            'bonus': listData['bonus'],
            'allowance': listData['allowance'],
            'net_salary': grossSalary - listData['absent_deduction'] - +listData['professional_tax'] - +listData['prev_month_sal_deduction'],
            'user_id': searchParams.get('id'),
            'month_year': getDateMonthString(searchParams.get('date')),
            'payout_id': listData['payout_id'],
        }
        updateSalary.handleAuth.mutate(data)
    }

    let showEditable = (!(listData['status'] === 'generated') || editable)

    return (
        <Layout>
            <div className='mx-6 my-4 flex justify-between'>
                <button onClick={() => navigate(-1, { replace: true })} className='flex items-center hover:underline mt-4'>
                    <ChevronLeftIcon className='w-6 h-6' />
                    <span className='font-semibold'>Back</span>
                </button>
                {
                    (listData['status'] === 'generated' && !editable) && <button onClick={() => setEditable(!editable)} type='button' className='bg-[color:var(--color1)] text-white  py-2 px-3 rounded'>Edit</button>
                }
            </div>
            {
                list?.isLoading ? (
                    <div className=' w-16 h-16 mx-auto mt-8'><img src={spinner} alt="spinner" className='w-full h-full' /></div>
                ) : (
                    <div className='mx-6 mb-10'>
                        <div className="document-body py-4  overflow-auto">
                            <table className='min-w-[35rem] w-[40%]'>
                                <thead>
                                    <tr><th colspan="2" className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Employee Details</th></tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Employee Name</td>
                                        <td className='border py-3 px-4'>{listData?.employee_name}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Designation</td>
                                        <td className='border py-3 px-4'>{listData?.designation}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Department</td>
                                        <td className='border py-3 px-4'>{listData?.department}</td>
                                    </tr>
                                    <tr>
                                        <td className='font-bold border py-3 px-4'>Date of Joining</td>
                                        <td className='border py-3 px-4'>{listData?.date_of_joining}</td>
                                    </tr>
                                </tbody>
                            </table>


                            <table className='mt-8 w-full'>
                                <thead>
                                    <tr>
                                        <th className='font-bold py-3 px-4 bg-[color:var(--color1)] text-white '>
                                            <button className='text-center bg-gray-200 py-1 px-2 rounded cursor-pointer text-gray-700 roboto font-semibold text-sm hover:bg-gray-300 mr-6' onClick={() => handlePrevViewDates('previous')}>
                                                Prev. Month
                                            </button>
                                        </th>
                                        <th colspan="3" className='font-bold py-3 px-4 bg-[color:var(--color1)] text-white'>

                                            Attendance Details
                                        </th>
                                        <th className='font-bold  py-3 px-4 bg-[color:var(--color1)] text-white'>
                                            <button className='text-center bg-gray-200 py-1 px-2 rounded cursor-pointer text-gray-700 roboto font-semibold text-sm hover:bg-gray-300 ml-6' onClick={() => handleViewDates('present')}>
                                                Curr. Month
                                            </button>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td className='font-bold border py-3 px-4 text-center'>Worked Days</td>
                                        <td className='font-bold border py-3 px-4 text-center'>Paid Leaves</td>
                                        <td className='font-bold border py-3 px-4 text-center'>Absent Days</td>
                                        <td className='font-bold border py-3 px-4 text-center'>Week Offs</td>
                                        <td className='font-bold border py-3 px-4 text-center'>Holidays</td>
                                    </tr>
                                    <tr>
                                        <td className='border py-3 px-4 text-center'>{listData['total_days_present']}</td>
                                        <td className='border py-3 px-4 text-center'>{listData['approved_leaves']}</td>
                                        <td className='border py-3 px-4 text-center'>{listData['total_days_absent']}</td>
                                        <td className='border py-3 px-4 text-center'>{listData['total_week_off']}</td>
                                        <td className='border py-3 px-4 text-center'>{listData['total_public_holidays']}</td>
                                    </tr>
                                </tbody>
                            </table>
                            <div className='flex flex-row mt-8'>
                                <table className='flex-1 mr-4'>
                                    <thead>
                                        <tr>
                                            <th className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Earnings</th>
                                            <th className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='font-bold border py-3 px-4'>Basic</td>
                                            <td className='border py-3 px-4'>₹ {listData?.base_salary}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4'>Allowance</td>
                                            <td className='border py-3 px-4 flex '>
                                                ₹
                                                <input
                                                    onChange={handleChange}
                                                    name="allowance"
                                                    className='w-full focus:outline-none ml-1'
                                                    disabled={!showEditable}
                                                    defaultValue={listData?.allowance}
                                                    type="number"
                                                />
                                                {showEditable && <PencilAltIcon className='w-4' />}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4'>Bonus</td>
                                            <td className='border py-3 px-4 flex '>₹ <input type="number" name="bonus" onChange={handleChange} className='w-full focus:outline-none ml-1' disabled={!showEditable} defaultValue={listData?.bonus} />{showEditable && <PencilAltIcon className='w-4' />}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4'>Commission</td>
                                            <td className='border py-3 px-4 flex '>₹ <input type="number" name="commission" onChange={handleChange} className='w-full focus:outline-none ml-1' disabled={!showEditable} defaultValue={listData?.commission} />{showEditable && <PencilAltIcon className='w-4' />}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border-t-2 border border-t-black py-3 px-4'>Total Earnings</td>
                                            <td className='border border-t-black border-t-2 py-3 px-4 font-bold'>₹ {+listData?.base_salary + +listData?.allowance + +listData?.bonus + +listData?.commission}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <table className='flex-1 ml-4'>
                                    <thead>
                                        <tr>
                                            <th className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Deduction</th>
                                            <th className='font-bold border py-3 px-4 bg-[color:var(--color1)] text-white'>Amount</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className='font-bold border py-3 px-4'>Professional Tax</td>
                                            <td className='border py-3 px-4'>₹ {listData?.professional_tax}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4'>Absent Deduction</td>
                                            <td className='border py-3 px-4'>₹ {listData?.absent_deduction}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4'>Prev. Month Deduction</td>
                                            <td className='border py-3 px-4'>₹ {listData?.prev_month_sal_deduction}</td>
                                        </tr>
                                        <tr>
                                            <td className='font-bold border py-3 px-4 border-t-black border-t-2'>Total Deductions</td>
                                            <td className='font-bold border py-3 px-4 border-t-black border-t-2'>₹ {+listData?.prev_month_sal_deduction + +listData?.absent_deduction + +listData?.professional_tax}</td>
                                        </tr>
                                        {/* <tr>
                                            <td className='font-bold border py-3 px-4'>Date of Joining</td>
                                            <td className='border py-3 px-4'>₹ {listData?.date_of_joining}</td>
                                        </tr> */}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <p className='my-3 mb-6 font-bold'>Net Salary: ₹ {+listData?.base_salary + +listData?.allowance + +listData?.bonus + +listData?.commission - listData['absent_deduction'] - +listData['professional_tax'] - +listData['prev_month_sal_deduction']}</p>
                        {
                            !(listData['status'] === 'generated') &&
                            <button onClick={handleCreateSalary} type='button' className='bg-[color:var(--color1)] text-white  py-2 px-3 rounded flex'>Save {createSalary.handleAuth.isLoading && <div className='flex flex-row items-center  w-6 h-6 mx-auto ml-2'><img src={spinner} alt="spinner" className='w-full h-full' /></div>}</button>
                        }
                        {
                            (listData['status'] === 'generated' && editable) && (
                                <div className='flex flex-row'>
                                    <button onClick={handleUpdateSalary} type='button' className='bg-[color:var(--color1)] text-white  py-2 px-3 rounded flex'>Update {updateSalary.handleAuth.isLoading && <div className='flex flex-row items-center  w-6 h-6 mx-auto ml-2'><img src={spinner} alt="spinner" className='w-full h-full' /></div>}</button>
                                    <button onClick={() => window.location.reload()} type='button' className='border-[color:var(--color1)] border-2 text-[color:var(--color1)]  py-2 px-3 rounded flex ml-4'>Cancel</button>
                                </div>
                            )
                        }
                    </div>

                )
            }
            {
                <Modal open={showDates} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                    <div className='document-wrapper px-6 divide-y bg-white h-max max-h-[80%] w-[40rem] ml-[30%] mt-[5%] overflow-auto'>
                        {
                            getCalendar.handleAuth.isLoading ? (
                                <div className=' w-16 h-16 mx-auto mt-8'><img src={spinner} alt="spinner" className='w-full h-full' /></div>
                            ) : (
                                <>
                                    <div className="document-head py-4">
                                        <div className='flex items-center'>
                                            <div className=' w-5 h-5 cursor-pointer mr-3' onClick={() => setShowDates(false)}>
                                                <ArrowLeftIcon className=' text-black w-full h-full' />
                                            </div>
                                            <h3 className=' text-gray-700 text-2xl font-semibold'>
                                                Calender
                                            </h3>
                                        </div>
                                    </div>
                                    <div className=" pt-2 pb-4 overflow-auto">

                                        {
                                            currentDatesSection === 'present' &&
                                            <>
                                                <div className='flex flex-row flex-wrap mb-4 justify-between'>
                                                    <div className='flex mt-4 flex-row mr-6 items-center'>
                                                        <p className='px-2 py-2 rounded flex items-center justify-center bg-[color:var(--color1)] text-white text-center uppercase font-semibold text-sm'></p>
                                                        <p className='ml-2 text-sm'>Present</p>
                                                    </div>
                                                    <div className='flex mt-4 flex-row mr-6 items-center'>
                                                        <p className='px-2 py-2 bg-[color:var(--color13)] rounded flex items-center justify-center text-white text-center uppercase font-semibold text-sm'></p>
                                                        <p className='ml-2 text-sm'>Absent</p>
                                                    </div>
                                                    <div className='flex mt-4 flex-row mr-6 items-center'>
                                                        <p className='px-2 py-2 bg-[color:var(--color8)] rounded flex items-center justify-center text-white text-center uppercase font-semibold text-sm'></p>
                                                        <p className='ml-2 text-sm'>Public Holidays</p>
                                                    </div>
                                                    <div className='flex mt-4 flex-row mr-6 items-center'>
                                                        <p className='px-2 py-2 bg-[color:var(--color14)] rounded flex items-center justify-center text-white text-center uppercase font-semibold text-sm'></p>
                                                        <p className='ml-2 text-sm'>Data pending</p>
                                                    </div>
                                                    <div className='flex mt-4 flex-row mr-6 items-center'>
                                                        <p className='px-2 py-2 bg-indigo-600 rounded flex items-center justify-center bg-[color:var(--color1)] text-white text-center uppercase font-semibold text-sm'></p>
                                                        <p className='ml-2 text-sm'>Approved Leaves</p>
                                                    </div>
                                                </div>
                                                <Calendar attendanceDates={{
                                                    days_present: listData['days_present'],
                                                    days_absent: listData['days_absent'],
                                                    approved_leaves_dates: listData['approved_leaves_dates'],
                                                    pending_dates: listData['pending_dates'],
                                                    month_days: listData['month_days'],
                                                    public_holidays: listData['public_holidays']
                                                }}
                                                    monthDateObject={filterData?.month_and_year} />
                                            </>
                                        }
                                        {
                                            currentDatesSection === 'previous' &&
                                            <>
                                                <div className='grid grid-cols-1 gap-2 pb-8'>
                                                    <p className='px-4 py-2 rounded flex items-center justify-center bg-[color:var(--color13)] text-white text-center uppercase font-semibold text-sm'>Prev Month Deduction For Current Month</p>
                                                </div>
                                                <Calendar attendanceDates={{
                                                    days_present: Array(),
                                                    days_absent: listData['previous_month_absent'],
                                                    approved_leaves_dates: Array(),
                                                    pending_dates: Array(),
                                                    month_days: listData['previous_month_days'],
                                                    public_holidays: Array()
                                                }}
                                                    monthDateObject={filterData?.prev_month_and_year} />
                                            </>
                                        }
                                    </div>
                                </>
                            )
                        }

                    </div>
                </Modal>
            }
            <Snackbar open={createSuccessMessage} autoHideDuration={6000} onClose={() => setCreateSuccessMessage(false)}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Payment Created Successfully
                </Alert>
            </Snackbar>
            <Snackbar open={updateSuccessMessage} autoHideDuration={6000} onClose={() => setUpdateSuccessMessage(false)}>
                <Alert severity="success" sx={{ width: '100%' }}>
                    Payment Updated Successfully
                </Alert>
            </Snackbar>
        </Layout>
    )
}

export default SalaryLogs