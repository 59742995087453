import React, { useState } from 'react'
import Cookies from 'universal-cookie';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { forgotPassword, loginUser } from '../../api/auth';
import ambrosianLogo from "../../assets/images/renderly_blue.png"
import Modals from '../../components/modal/Modals';
import spinner from '../../assets/images/spinner.gif'
import { config } from '../../config';


const ForgotPassword = () => {
  const [userData, setuserData] = useState({})
  const [error, setError] = useState('')
  const [isSuccessModal, setIsSuccessModal] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  let navigate = useNavigate()

  const handleSetUserData = (e) => {
    let value = e.target.value
    setuserData({
      ...userData,
      [e.target.name]: value
    })
  }


  const Submit = (e) => {
    setIsLoading(true)
    e.preventDefault();
    let data = {
      ...userData,
      url: config.frontEndUrl + 'reset-password?code=',
    }
    forgotPassword(data)
      .then((res) => {
        setIsLoading(false)
        setIsSuccessModal(true)
        setError('')
      })
      .catch((err) => {
        setIsLoading(false)
        setError(err?.response?.data?.message)
      })

  }

  const handleClose = () => {
    setError('')
    setIsSuccessModal(false)
  }

  return (
    <>
      <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100">
        <div className="flex flex-col items-center justify-center w-full flex-1 px-20 ">
          <div className="bg-white rounded-2xl shadow-2xl flex w-2/3 max-w-4xl">
            <div className="w-3/5 py-20 px-9">
              <p className=" text-3xl font-semibold mb-8 roboto">Forgot Password</p>
              <form onSubmit={Submit} className=" space-y-4">
                <div>
                  <label for="email" className="label">Email or Username</label>
                  <input type="text" required
                    className="input"
                    name="email" placeholder="Enter your email or username here" value={userData.email} onChange={handleSetUserData}></input>
                </div>
                {error && <div className='text-red-700 text-center'>
                  <small>{error}</small>
                </div>}
                {isLoading ? <div className=' w-8 h-8 mx-auto'>
                  <img src={spinner} alt="spinner" className='w-full h-full' />
                </div> : <div className="button">
                  <button type="submit" className=" w-full py-2 bg-[color:var(--color1)] text-white hover:bg-[color:var(--color9)] rounded roboto">Forgot Password</button>
                </div>}
                <p className='text-sm'>Don't have an account? <Link to={"/register-company"} className='text-blue-600 underline text-sm'>Register</Link></p>
              </form>
            </div>
            <div className="w-2/5  text-white rounded-r-2xl rounded-br-2xl py-36 px-12 text-center ">
              <p className=" roboto text-sl font-semibold text-[color:var(--color1)]">Welcome To <span className="font-extrabold">Renderly HRMS</span></p>
              <div className=" flex justify-center mt-8">
                <img src={ambrosianLogo} alt="logo" className=' h-40' />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modals show={isSuccessModal}>
        <div>
          <p className=' roboto'>A mail has been to this email account</p>
          <div className=" mt-12">
            <button onClick={handleClose} className='  bg-[color:var(--color1)] text-white px-5 py-2 rounded'>Ok</button>
          </div>
        </div>
      </Modals>
    </>
  )
}

export default ForgotPassword