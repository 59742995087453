import React, { useEffect, useState } from 'react'
import { postEmployeeDetails } from '../../api/employeeDetails'
import _ from 'lodash'
import Layout from '../../components/layout/Layout'
import Radio from '../../components/radio/Radio'
import { Link, useLocation } from "react-router-dom";
import Modals from '../../components/modal/Modals';
import { generatePassword, taxCalculation, taxCalculationconstultant, taxCalculationEmployee } from '../../utility';
import { ABOVE_LIMIT_TAX, PERCENTILE, TAX, TDS } from '../../config';
import { type } from '@testing-library/user-event/dist/type'
import spinner from '../../assets/images/spinner.gif'
import Cookies from 'universal-cookie';
import { getUserName } from '../../api'
import DialogMessage from '../../components/DialogMessage'
import { CopyToClipboard } from 'react-copy-to-clipboard';
const cookies = new Cookies
let val = [0, 0, 0]
let IS_REQUIRED = true
function AddEmployeeDetails() {
    const [role, setRole] = useState('')
    const [empCreateError, setEmpCreateError] = useState('')
    const [errorObject, setErrorObject] = useState(null)
    const initialState = {
        first_name: '',
        last_name: '',
        address_1: '',
        address_2: '',
        pincode: '',
        city: '',
        email: '',
        DOB: '',
        gender: '',
        mobile_number: '',
        home_phone: '',
        bank_name: '',
        ifsc_code: '',
        account_number: '',
        pan_number: '',
        aadhar_number: '',
        qualification: '',
        work_experience: '',
        designation: '',
        department: '',
        date_of_joining: '',
        // date_of_leaving: '',
        username_surfix: '',
        username_prefix: '',
        password: '',
        salary: '',
        professional_tax: 0,
        tds: 0,
        gst: 0,
        gst_number: '',
        isConsultant: false,
        gross_salary: 0,
        type_of_employee: '',
        pl_leave: '',
        dl_leave: '',
        cl_leave: '',
        sl_leave: '',
        ol_leave: '',
        total_leave: '',
    }
    const [isLoading, setIsLoading] = useState(false)
    const [employeDetail, setEmployeDetail] = useState(initialState)
    const [isShowingModal, setIsShowingModal] = useState(false)
    const [isShowingCopiedModal, setIsShowingCopiedModal] = useState(false)
    const [isConsultant, setIsConsultant] = useState(false)
    const [educationFile, setEducationFile] = useState([])
    const [workDoc, setWorkDocs] = useState([])
    const handleSetEmployeDetail = (e) => {
        const value = e.target.value
        if (e.target.name === 'cl_leave' || e.target.name === 'pl_leave' || e.target.name === 'sl_leave' || e.target.name === 'dl_leave' || e.target.name === 'ol_leave') {
            const id = e.target.getAttribute("data-id")
            val[id] = +value
            const sum = val.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0,
            );
            let data = {
                ...employeDetail,
                [e.target.name]: +value,
                total_leave: sum
            }
            setEmployeDetail(data)
            return data
        }
        else {
            setEmployeDetail({
                ...employeDetail,
                [e.target.name]: value
            })
        }
        if (e.target.name === 'gst_number') {
            let data = { ...employeDetail }
            if (value === '' || !value || value === undefined) {
                data = { ...employeDetail, gst: 0, [e.target.name]: value }
                let calculatedData = taxCalculation(data)
                setEmployeDetail(calculatedData)
            } else {
                data = {
                    ...employeDetail,
                    [e.target.name]: value
                }
                setEmployeDetail(data)
            }
        }
        return {
            ...employeDetail,
            [e.target.name]: value,
        }
    }
    const handleConsultant = (e, type) => {
        if (type === 'consultant') {
            setIsConsultant(true)
            let data = {
                ...employeDetail,
                [e.target.name]: e.target.value,
                salary: 0,
                professional_tax: 0,
                tds: 0,
                gst: 0,
                gross_salary: 0,
                cl_leave: '',
                pl_leave: '',
                sl_leave: '',
                ol_leave: '',
                total_leave: '',
            }
            setEmployeDetail(data)
            taxCalculation(handleSetEmployeDetail(e))
        }
        else if (type === 'employee') {
            setIsConsultant(false)
            let data = {
                ...employeDetail,
                [e.target.name]: e.target.value,
                salary: 0,
                professional_tax: 0,
                tds: 0,
                gst: 0,
                gross_salary: 0,
                cl_leave: '',
                pl_leave: '',
                sl_leave: '',
                ol_leave: '',
                total_leave: '',
            }
            setEmployeDetail(data)
            taxCalculation(handleSetEmployeDetail(e))
        }
    }
    const handleTax = (e) => {
        let data = taxCalculation(handleSetEmployeDetail(e))
        setEmployeDetail(data)
        // console.log(data)
    }
    const handleGeneratePassword = () => {
        var password = generatePassword({
            uppercase: true,
            lowercase: true,
            symbols: true,
            numbers: true
        })
        let data = {
            ...employeDetail,
            password: password,
        }
        setEmployeDetail(data)
        return data
    }
    const handleSubmitEmployeDetail = (e) => {
        setIsLoading(true)
        e.preventDefault()
        postEmployeeDetails(employeDetail, educationFile, workDoc)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setIsShowingModal(true)
                    setIsLoading(false)
                }
                setErrorObject(null)
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
                if (err?.response?.data?.message === "Required Fields") {
                    setErrorObject(err?.response?.data?.data)
                }
                setEmpCreateError(err?.response?.data?.message)
            })
    }
    const handleGetUserName = (role) => {
        getUserName(role)
            .then((res) => {
                if (res.status === 200) {
                    let data = {
                        ...employeDetail,
                        username_prefix: '',
                        username_surfix: res?.data.username_surfix
                    }
                    setEmployeDetail(data)
                }
            })
            .catch((err) => {
                console.log(err)
            })
    }

    const copyUrl = async () => {
        setIsShowingCopiedModal(true)
    }

    useEffect(() => {
        if (employeDetail.type_of_employee) {
            handleGetUserName(employeDetail.type_of_employee)
        }
    }, [employeDetail.type_of_employee])
    return (
        <Layout>
            <div className="">
                <div className=" container">
                    <div className=" px-4 py-4 text-start">
                        <Link to={"/employe-details"} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Back</Link>
                    </div>
                    <form action="" method="POST" onSubmit={handleSubmitEmployeDetail}>
                        <small className=' px-4 py-4'><span className=' mandatory'>*</span> Fields are mandatory</small>
                        <div className=" ">
                            <div className="px-4 my-4">
                                <p className='label'>User Type:</p>
                                <div className=" flex gap-x-4" >
                                    <Radio label={"Consultant"} name={"type_of_employee"} value={"consultant"} onChange={(e) => handleConsultant(e, 'consultant')} />
                                    <Radio label={"Employee"} name={"type_of_employee"} value={"employee"} onChange={(e) => handleConsultant(e, 'employee')} />
                                </div>
                            </div>
                            {employeDetail.type_of_employee &&
                                <>
                                    <div>
                                        <div className=" grid grid-cols-2 gap-4 px-4 auth-section ">
                                            <h1 className='mt-4 text-2xl font-semibold'>Personal Details</h1><div></div>
                                            <div className="">
                                                <label htmlFor="salary" className='label'>Username:<span className='mandatory'>*</span></label>
                                                <input type="text" name='username' className='input disabled:cursor-not-allowed' required={IS_REQUIRED} value={employeDetail.username_prefix + employeDetail.username_surfix} readOnly disabled placeholder='Username' />
                                            </div>
                                            <div>
                                                <div className=' grid grid-cols-2 gap-4 items-end'>
                                                    <div className="">
                                                        <label htmlFor="gross_salary" className='label'>Password</label>
                                                        <input type="text" name='password' readOnly disabled className='input disabled:cursor-not-allowed' placeholder='Password' value={employeDetail.password} />
                                                    </div>
                                                    <div className="">
                                                        <label htmlFor="gross_salary" className='label'></label>
                                                        <button type='button' onClick={handleGeneratePassword} className='px-5 py-2 rounded bg-[color:var(--color9)] mt-9 text-white'>Generate Password</button>
                                                    </div>
                                                </div>
                                                {errorObject?.password && <p className='text-xs text-red-600 mt-2'>{errorObject?.password}</p>}
                                            </div>
                                            <div>
                                                <CopyToClipboard onCopy={copyUrl} text={"Username: " + employeDetail.username_prefix + employeDetail.username_surfix + ", Password: " + employeDetail.password}>
                                                    <button type='button' className='px-5 py-2 rounded bg-[color:var(--color9)] text-white'>Copy Username and Password</button>
                                                </CopyToClipboard>
                                            </div>
                                        </div>

                                        <div className=" grid grid-cols-2 gap-4 p-4 personalDetail-section">
                                            <div className="">
                                                <label htmlFor="first_name" className='label'>First Name:<span className='mandatory'>*</span></label>
                                                <input type="text" name='first_name' required={IS_REQUIRED} value={employeDetail.first_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter first name ' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="last_name" className='label'>Last Name:<span className='mandatory'>*</span></label>
                                                <input type="text" name='last_name' required={IS_REQUIRED} value={employeDetail.last_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter last name ' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="DOB" className='label'>Date Of Birth:<span className='mandatory'>*</span></label>
                                                <input type="date" name='DOB' className='input' required={IS_REQUIRED} value={employeDetail.DOB} onChange={handleSetEmployeDetail} placeholder='Enter DOB ' />
                                            </div>
                                            <div className="">
                                                <p className='label'>Gender:<span className='mandatory'>*</span></p>
                                                <div className=" flex gap-x-4" >
                                                    <Radio label={"Male"} name={"gender"} value={"male"} onChange={handleTax} />
                                                    <Radio label={"Female"} name={"gender"} value={"female"} onChange={handleTax} />
                                                </div>
                                                {errorObject?.gender && <p className='text-xs text-red-600 mt-2'>{errorObject?.gender}</p>}
                                            </div>
                                            <div className="">
                                                <label htmlFor="email" className='label'>Email:<span className='mandatory'>*</span></label>
                                                <input type="text" name='email' className='input' required={IS_REQUIRED} value={employeDetail.email} onChange={handleSetEmployeDetail} placeholder='Email' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="mobile_number" className='label'>Mobile Number:<span className='mandatory'>*</span></label>
                                                <input type="tel" name='mobile_number' maxLength={10} required={IS_REQUIRED} className='input' value={employeDetail.mobile_number} onChange={handleSetEmployeDetail} placeholder='Enter mobile number' />
                                                {errorObject?.mobile_number && <p className='text-xs text-red-600 mt-2'>{errorObject?.mobile_number}</p>}
                                            </div>
                                            <div className="">
                                                <label htmlFor="home_phone" className='label'>Alternate Number:</label>
                                                <input type="tel" name='home_phone' maxLength={10} className='input' value={employeDetail.home_phone} onChange={handleSetEmployeDetail} placeholder='Alternate Number' />
                                            </div>
                                            <div></div>
                                            <h1 className='mt-8 text-2xl font-semibold'>Address Details</h1><div></div>
                                            <div className="">
                                                <label htmlFor="address_1" className='label'>Address Line 1:<span className='mandatory'>*</span></label>
                                                <input type="text" name='address_1' className='input' required={IS_REQUIRED} value={employeDetail.address_1} onChange={handleSetEmployeDetail} placeholder='Enter address line 1 code' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="address_2" className='label'>Address Line 2:</label>
                                                <input type="text" name='address_2' className='input' value={employeDetail.address_2} onChange={handleSetEmployeDetail} placeholder='Enter address line 2 code' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="pincode" className='label'>Pincode:<span className='mandatory'>*</span></label>
                                                <input type="text" name='pincode' className='input' required={IS_REQUIRED} value={employeDetail.pincode} onChange={handleSetEmployeDetail} placeholder='Pincode' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="city" className='label'>City:<span className='mandatory'>*</span></label>
                                                <input type="text" name='city' className='input' required={IS_REQUIRED} value={employeDetail.city} onChange={handleSetEmployeDetail} placeholder='Enter city ' />
                                            </div>
                                            <h1 className='mt-4 text-2xl font-semibold'>Bank Details</h1><div></div>
                                            <div className="">
                                                <label htmlFor="bank_name" className='label'>Bank Name: <span className='mandatory'>*</span></label>
                                                <input type="text" name='bank_name' className='input' required={IS_REQUIRED} value={employeDetail.bank_name} onChange={handleSetEmployeDetail} placeholder='Bank name' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="ifsc_code" className='label'>IFSC Code:<span className='mandatory'>*</span></label>
                                                <input type="text" name='ifsc_code' className='input' required={IS_REQUIRED} value={employeDetail.ifsc_code} onChange={handleSetEmployeDetail} placeholder='IFSC Code' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="account_number" className='label'>Account Number:<span className='mandatory'>*</span></label>
                                                <input type="tel" name='account_number' className='input' required={IS_REQUIRED} value={employeDetail.account_number} onChange={handleSetEmployeDetail} placeholder='Account number' />
                                            </div>
                                            {isConsultant ? <div className="">
                                                <label htmlFor="account_number" className='label'>GST Number:</label>
                                                <input type="text" name='gst_number' className='input' value={employeDetail.gst_number} onChange={handleSetEmployeDetail} placeholder='GST Number' />
                                            </div> : <br />}
                                            <div className="">
                                                <label htmlFor="pan_number" className='label'>Pan Number:<span className='mandatory'>*</span></label>
                                                <input type="text" name='pan_number' className='input' required={IS_REQUIRED} value={employeDetail.pan_number} onChange={handleSetEmployeDetail} placeholder='Pan number' />
                                                {errorObject?.pan_number && <p className='text-xs text-red-600 mt-2'>{errorObject?.pan_number}</p>}
                                            </div>
                                            <div className="">
                                                <label htmlFor="pan_number" className='label'>Aadhar Number:<span className='mandatory'>*</span></label>
                                                <input type="tel" maxLength={12} name='aadhar_number' className='input' required={IS_REQUIRED} value={employeDetail.aadhar_number} onChange={handleSetEmployeDetail} placeholder='Aadhar number' />
                                                {errorObject?.aadhar_number && <p className='text-xs text-red-600 mt-2'>{errorObject?.aadhar_number}</p>}
                                            </div>
                                            <h1 className='mt-4 text-2xl font-semibold'>Work Details</h1><div></div>
                                            <div className="">
                                                <label htmlFor="qualification" className='label'>Educational Qualification : <span className='mandatory'>*</span></label>
                                                <input type="text" name='qualification' className='input' required={IS_REQUIRED} value={employeDetail.qualification} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
                                            </div>
                                            <div className=" ">
                                                <label htmlFor="education_certificates" className='label'>Education Certificates:</label>
                                                <input type="file" multiple name='education_certificates' className='input border-none' onChange={(e) => setEducationFile(e)} placeholder='Enter your qualification' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="work_experience" className='label'>Work Experience : <small>{`(In year)`}</small><span className='mandatory'>*</span></label>
                                                <input type="text" name='work_experience' className='input' required={IS_REQUIRED} value={employeDetail.work_experience} onChange={handleSetEmployeDetail} placeholder='Enter your Work Experience' />
                                            </div>
                                            <div className=" ">
                                                <label htmlFor="work_documents" className='label'>Work Experience Documents:</label>
                                                <input type="file" multiple name='work_documents' className='input border-none' onChange={(e) => setWorkDocs(e)} placeholder='Enter your work experience' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="designation" className='label'>Designation:<span className='mandatory'>*</span></label>
                                                <input type="text" name='designation' className='input' required={IS_REQUIRED} value={employeDetail.designation} onChange={handleSetEmployeDetail} placeholder='Designation' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="department" className='label'>Department:<span className='mandatory'>*</span></label>
                                                <input type="text" name='department' className='input' required={IS_REQUIRED} value={employeDetail.department} onChange={handleSetEmployeDetail} placeholder='Department' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="date_of_joining" className='label'>Date Of Joining:<span className='mandatory'>*</span></label>
                                                <input type="date" name='date_of_joining' className='input' required={IS_REQUIRED} value={employeDetail.date_of_joining} onChange={handleSetEmployeDetail} placeholder='Date of joining' />
                                            </div>
                                            {/* <div className="">
                                                <label htmlFor="date_of_leaving" className='label'>Date Of Leaving:</label>
                                                <input type="date" name='date_of_leaving' className='input' value={employeDetail.date_of_leaving} onChange={handleSetEmployeDetail} placeholder='Date of leaving' />
                                            </div> */}
                                        </div>
                                        <br />
                                        <div className=" grid grid-cols-2 gap-4 p-4 bankDetail-section">
                                            <h1 className='mt-4 text-2xl font-semibold'>Salary Details</h1><div></div>
                                            <div className="">
                                                <label htmlFor="salary" className='label'>Gross Salary / Consultant Fee:<span className='mandatory'>*</span></label>
                                                <input type="text" name='salary' className='input' required={IS_REQUIRED} value={employeDetail.salary} onChange={handleTax} placeholder='Salary / Consultant Fee' />
                                            </div>
                                            {isConsultant ? <div className="">
                                                <label htmlFor="gst" className='label'>GST <small>{`(In Amount)`}</small></label>
                                                <input type="text" name='gst' className='input disabled:cursor-not-allowed' disabled={!employeDetail.gst_number} value={employeDetail.gst} onChange={handleTax} placeholder='Enter gst' />
                                                <small className=' text-gray-500 font-semibold'>Please Enter GST Number to avail GST</small>
                                            </div> : <div className="">
                                                <label htmlFor="professional_tax" className='label'>Professional Tax:</label>
                                                <input type="text" name='professional_tax' readOnly disabled className='input disabled:cursor-not-allowed' value={employeDetail.professional_tax} onChange={handleTax} placeholder='Professional tax' />
                                            </div>}
                                            <div className="">
                                                <label htmlFor="tds" className='label'>TDS: {isConsultant && <span className='mandatory'>*</span>} <small>{`(In Amount)`}</small></label>
                                                <input type="text" name='tds' className='input disabled:cursor-not-allowed' readOnly={isConsultant} disabled={isConsultant} value={employeDetail.tds} onChange={handleTax} placeholder='TDS' />
                                            </div>
                                            <div className="">
                                                <label htmlFor="gross_salary" className='label'>Net Salary / Consultant Fee:</label>
                                                <input type="text" name='gross_salary' readOnly disabled className='input disabled:cursor-not-allowed' value={employeDetail.gross_salary} placeholder='Gross Salary / Consultant Fee' />
                                            </div>
                                        </div>
                                        <br />
                                        <div className=" grid grid-cols-2 gap-4 p-4 leaveAllot-section">
                                            <h1 className='mt-4 text-2xl font-semibold'>Leave Details</h1><div></div>
                                            {!isConsultant ? <>
                                                <div className="">
                                                    <label htmlFor="pl_leave" className='label'>Privilege Leave:</label>
                                                    <input type="text" name='pl_leave' data-id='1' id='pl_leave' className='input' value={employeDetail.pl_leave} onChange={handleSetEmployeDetail} placeholder='Privilege Leave' />
                                                </div>
                                                <div className="">
                                                    <label htmlFor="cl_leave" className='label'>Casual Leave:</label>
                                                    <input type="text" name='cl_leave' data-id='0' id='cl_leave' className='input disabled:cursor-not-allowed' value={employeDetail.cl_leave} onChange={handleSetEmployeDetail} placeholder='Casual leave' />
                                                </div>
                                            </> :
                                                <>
                                                    <div className="">
                                                        <label htmlFor="ol_leave" className='label'>Other Leave:</label>
                                                        <input type="text" name='ol_leave' data-id='1' id='ol_leave' className='input' value={employeDetail.ol_leave} onChange={handleSetEmployeDetail} placeholder='Other Leave' />
                                                    </div>
                                                    <div className="">
                                                        <label htmlFor="dl_leave" className='label'>Discretionary Leave:</label>
                                                        <input type="text" name='dl_leave' data-id='0' id='dl_leave' className='input' value={employeDetail.dl_leave} onChange={handleSetEmployeDetail} placeholder='Discretional Leave' />
                                                    </div>
                                                </>
                                            }
                                            {!isConsultant && <div className="">
                                                <label htmlFor="sl_leave" className='label'>Sick Leave:</label>
                                                <input type="text" name='sl_leave' data-id='2' id='sl_leave' className='input disabled:cursor-not-allowed' disabled={isConsultant} value={employeDetail.sl_leave} onChange={handleSetEmployeDetail} placeholder='Sick leave' />
                                            </div>}
                                            <div className="">
                                                <label htmlFor="total_leave" className='label'>Total Leave:</label>
                                                <input type="text" name='total_leave' readOnly disabled required={IS_REQUIRED} className='input disabled:cursor-not-allowed' value={employeDetail.total_leave} onChange={handleSetEmployeDetail} placeholder='Total leaves' />
                                                {errorObject?.total_leave && <p className='text-xs text-red-600 mt-2'>{errorObject?.total_leave}</p>}
                                            </div>
                                        </div>
                                    </div>
                                    {isLoading ? <div className=' w-14 h-14 mx-auto my-8'>
                                        <img src={spinner} alt="spinner" className='w-full h-full' />
                                    </div> : <div className=" text-center my-8">
                                        <button type='submit' className=' bg-[color:var(--color1)] hover:bg-[color:var(--color12)] text-white py-3 px-20 rounded-full'>Submit</button>
                                    </div>}
                                </>
                            }
                        </div>
                    </form>
                </div>
            </div>
            <Modals show={isShowingModal}>
                <div>
                    <p className=' roboto'>Your Data is Sucessfully Stored </p>
                    <div className=" mt-12">
                        <a href={"/employe-details"} className='  bg-[color:var(--color1)] text-white px-5 py-2 rounded'>Ok</a>
                    </div>
                </div>
            </Modals>
            <Modals show={isShowingCopiedModal}>
                <div>
                    <p className=' roboto'>Your Data is successfully copied</p>
                    <div className=" mt-12">
                        <p onClick={() => { setIsShowingCopiedModal(false) }} className='w-max  bg-[color:var(--color1)] text-white px-5 py-2 rounded hover:cursor-pointer'>Ok</p>
                    </div>
                </div>
            </Modals>
            <DialogMessage open={empCreateError} type={"Error"} message={empCreateError === "Required Fields" ? "Some fields are missing" : empCreateError} handleClose={() => setEmpCreateError('')} />
        </Layout>
    )
}

export default AddEmployeeDetails