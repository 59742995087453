import React, { useState } from 'react'
import Layout from '../../components/layout/Layout'
import spinner from '../../assets/images/spinner.gif'
import { Modal } from '@mui/material'
import { PencilAltIcon, TrashIcon, XIcon } from '@heroicons/react/solid'
import Radio from '../../components/radio/Radio'
import { deleteHolidayForm, getholidayList, postHolidayForm, updateHolidayForm } from '../../api/holiday'
import { useEffect } from 'react'
import Moment from 'react-moment';

let IS_REQUIRED = true
function Holidays() {
    const initialState = {
        holiday_type: 'single',
        from_date: '',
        to_date: '',
        title: '',
        applicable: '',
    }
    const [holidayId, setHolidayId] = useState('')
    const [holidayList, setHolidayList] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [holidayData, setHolidayData] = useState(initialState)
    const [holidayModal, setHolidayModal] = useState(false)
    const [isAddingData, setAddingData] = useState(false)
    const [deleteConfirmationModal, setDeleteConfirmationModal] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [openHolidayEditModal, setOpenHolidayEditModal] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)
    useEffect(() => {
        handleFetchHoliday()
    }, [])
    const handleFetchHoliday = () => {
        setIsLoading(true)
        getholidayList()
            .then((res) => {
                setHolidayList(res)
                setIsLoading(false)
            })
            .catch((err) => {
                alert('Something went wrong!')
                setIsLoading(false)
            })
    }

    const handleHolidayFormInputs = (e) => {
        let value = e.target.value
        let data = {
            ...holidayData,
            [e.target.name]: value
        }
        setHolidayData(data)
    }

    const handleSubmitFormData = (e) => {
        setAddingData(true)
        e.preventDefault()
        postHolidayForm(holidayData)
            .then(res => {
                if (res.status === 200) {
                    handleFetchHoliday()
                    setAddingData(false)
                    setHolidayModal(false)
                    setHolidayData(initialState)
                }
            })
            .catch(err => {
                console.log(err)
            })
    }

    const handleCancelForm = () => {
        setHolidayModal(false)
        setHolidayData(initialState)
        setOpenHolidayEditModal(false)
    }

    const handleOpenDeleteModal = (id) => {
        setHolidayId(id)
        setDeleteConfirmationModal(true)
    }
    const handleDeleteLeave = () => {
        setIsDeleting(true)
        deleteHolidayForm(holidayId)
            .then((res) => {
                if (res.status === 200) {
                    setDeleteConfirmationModal(false)
                    handleFetchHoliday()
                    setIsDeleting(false)
                }
            })
            .catch((err) => {
                alert('Something went wrong!')
                setIsDeleting(false)
            })
    }

    const handleGetSingleLeaveData = (id) => {
        setHolidayData(holidayList.find(data => data.id === id))
        setOpenHolidayEditModal(true)
    }


    const handleEditHoliday = (e) => {
        setIsUpdating(true)
        e.preventDefault()
        updateHolidayForm(holidayData)
            .then((res) => {
                if (res.status === 200) {
                    handleFetchHoliday()
                    setOpenHolidayEditModal(false)
                    setHolidayData(initialState)
                    setIsUpdating(false)
                }
            })
            .catch(err => {
                console.log(err)
                setIsUpdating(false)
            })
    }
    return (
        <Layout>
            {isLoading ? <div className=' w-16 h-16 mx-auto mt-8'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div class="max-w-full overflow-x-auto px-4 mt-8">
                <div className='text-end mb-4'>
                    <button className=" bg-[color:var(--color1)] text-white rounded px-4 py-4 text-lg" onClick={() => setHolidayModal(true)}>Add holiday</button>
                </div>
                {holidayList.length <= 0 ? <div className='text-center'><p className=' text-gray-700 text-3xl'>No Holidays To Show</p></div> : <table class="table-auto w-full">
                    <thead>
                        <tr class=" bg-[color:var(--color1)] text-center">
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Holiday Name</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">From - To Date</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Applicable To</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {holidayList?.map((data, idx) => {
                            const { id, title, from_date, to_date, holiday_type,applicable } = data
                            return <tr key={idx}>
                                <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{idx + 1}</td>
                                <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{title}</td>
                                <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                    <Moment format='ll'>{from_date}</Moment>
                                    {holiday_type === 'multiple' && <> - <Moment format='ll'>{to_date}</Moment></>}
                                </td>
                                <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">{applicable.toLowerCase()}</td>
                                <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-r border-[#E8E8E8]">
                                    <div className=" flex justify-center space-x-1">
                                        <button className=" " onClick={() => handleGetSingleLeaveData(id)}>
                                            <PencilAltIcon className=' text-gray-600 w-6 hover:text-blue-600' />
                                        </button>
                                        <div className="" >
                                            <TrashIcon className=' text-gray-600 w-6 hover:text-orange-600 cursor-pointer' onClick={() => handleOpenDeleteModal(id)} />
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        })}
                    </tbody>
                </table>}
            </div>}
            <Modal open={holidayModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                    <div className='document-wrapper px-4 divide-y'>
                        <div className="document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h3 className=' text-gray-700 text-3xl font-semibold'>Public Holiday</h3>
                                <div className=' w-6 h-6 cursor-pointer' onClick={handleCancelForm}>
                                    <XIcon className=' text-black w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className="document-body max-h-96 overflow-auto">
                            <form method="post " className='divide-y' onSubmit={handleSubmitFormData}>
                                <div className="grid grid-cols-2 gap-4 py-4">
                                    <div className="">
                                        <p className='label'>Holiday Type:<span className='mandatory'>*</span></p>
                                        <div className=" flex gap-x-4" >
                                            <Radio label={"Single"} name={"holiday_type"} value={"single"} val={holidayData.holiday_type} onChange={handleHolidayFormInputs} />
                                            <Radio label={"Multiple"} name={"holiday_type"} value={"multiple"} val={holidayData.holiday_type} onChange={handleHolidayFormInputs} />
                                        </div>
                                    </div>
                                    <div className="">
                                        <label htmlFor="holiday_name" className='label'>Holiday Name:<span className='mandatory'>*</span></label>
                                        <input type="text" name='title' required={IS_REQUIRED} className='input' placeholder='Enter Holiday name ' value={holidayData.title} onChange={handleHolidayFormInputs} />
                                    </div>
                                    <div className="">
                                        <label htmlFor="from_date" className='label'>From Date:<span className='mandatory'>*</span></label>
                                        <input type="date" name='from_date' className='input' required={IS_REQUIRED} value={holidayData.from_date} onChange={handleHolidayFormInputs} placeholder='Date of joining' />
                                    </div>
                                    {holidayData.holiday_type === 'multiple' && <div className="">
                                        <label htmlFor="to_date" className='label'>To Date</label>
                                        <input type="date" name='to_date' className='input' value={holidayData.to_date} onChange={handleHolidayFormInputs} placeholder='Date of leaving' />
                                    </div>}
                                    <div className="">
                                        <label htmlFor="Applicable To" className='label'>Applicable To<span className='mandatory'>*</span></label>
                                        <select name="applicable" className='input' required={IS_REQUIRED} value={holidayData.applicable} onChange={handleHolidayFormInputs}>
                                            <option value="">-- Select Applicable To --</option>
                                            <option value="BOTH">Both</option>
                                            <option value="EMPLOYEE">Employee</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="document-foot space-x-4 py-4">
                                    {isAddingData ? <div className=' w-8 h-8 mx-auto'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className="text-center space-x-4">
                                        <button className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white'>Save</button>
                                        <button type='button' className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={handleCancelForm}>Cancel</button>
                                    </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={openHolidayEditModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                    <div className='document-wrapper px-4 divide-y'>
                        <div className="document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h3 className=' text-gray-700 text-3xl font-semibold'>Edit Public Holiday</h3>
                                <div className=' w-6 h-6 cursor-pointer' onClick={handleCancelForm}>
                                    <XIcon className=' text-black w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className="document-body max-h-96 overflow-auto">
                            <form method="post " className='divide-y' onSubmit={handleEditHoliday}>
                                <div className="grid grid-cols-2 gap-4 py-4">
                                    <div className="">
                                        <p className='label'>Holiday Type:<span className='mandatory'>*</span></p>
                                        <div className=" flex gap-x-4" >
                                            <Radio label={"Single"} name={"holiday_type"} value={"single"} val={holidayData.holiday_type} onChange={handleHolidayFormInputs} />
                                            <Radio label={"Multiple"} name={"holiday_type"} value={"multiple"} val={holidayData.holiday_type} onChange={handleHolidayFormInputs} />
                                        </div>
                                    </div>
                                    <div className="">
                                        <label htmlFor="holiday_name" className='label'>Holiday Name:<span className='mandatory'>*</span></label>
                                        <input type="text" name='title' required={IS_REQUIRED} className='input' placeholder='Enter Holiday name ' value={holidayData.title} onChange={handleHolidayFormInputs} />
                                    </div>
                                    <div className="">
                                        <label htmlFor="from_date" className='label'>From Date:<span className='mandatory'>*</span></label>
                                        <input type="date" name='from_date' className='input' required={IS_REQUIRED} value={holidayData.from_date} onChange={handleHolidayFormInputs} placeholder='Date of joining' />
                                    </div>
                                    {holidayData.holiday_type === 'multiple' && <div className="">
                                        <label htmlFor="to_date" className='label'>To Date</label>
                                        <input type="date" name='to_date' className='input' value={holidayData.to_date} onChange={handleHolidayFormInputs} placeholder='Date of leaving' />
                                    </div>}
                                    <div className="">
                                        <label htmlFor="Applicable To" className='label'>Applicable To<span className='mandatory'>*</span></label>
                                        <select name="applicable" className='input' required={IS_REQUIRED} value={holidayData.applicable} onChange={handleHolidayFormInputs}>
                                            <option value="">-- Select Applicable To --</option>
                                            <option value="BOTH">Both</option>
                                            <option value="EMPLOYEE">Employee</option>
                                        </select>
                                    </div>
                                </div>
                                <div className="document-foot space-x-4 py-4">
                                    {isUpdating ? <div className=' w-8 h-8 mx-auto'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className="text-center space-x-4">
                                        <button className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white'>Update</button>
                                        <button type='button' className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={handleCancelForm}>Cancel</button>
                                    </div>}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal open={deleteConfirmationModal}>
                <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                    <div className='document-wrapper px-4 divide-y'>
                        <div className="document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h3 className=' text-gray-700 text-3xl font-semibold'>Delete Holiday</h3>
                                <div className=' w-6 h-6 cursor-pointer' onClick={() => setDeleteConfirmationModal(false)}>
                                    <XIcon className=' text-black w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className="document-body max-h-96 overflow-auto py-4">
                            <div>
                                <p className=' roboto text-center font-bold text-2xl text-gray-700 mb-4'>Are you sure you want to delete this Field?</p>
                                {isDeleting ? <div className=' w-8 h-8 mx-auto'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className="space-x-3 text-center ">
                                    <button className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={handleDeleteLeave}>Yes</button>
                                    <button className='px-4 py-2 rounded-md font-semibold  border' onClick={() => setDeleteConfirmationModal(false)}>Cancel</button>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </Layout>
    )
}

export default Holidays