import axios from "axios";
import Cookies from "universal-cookie";
import { config } from "../config";
const cookies = new Cookies();


export const getholidayList = async () => {
    let token = cookies.get("_am_token");
    var res = await axios({ method: "GET", url: `${config.apiurl}holidaylist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res.data
};
export const postHolidayForm = async (data) => {
    let token = cookies.get("_am_token");
    var form_data = new FormData();
    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}holiday_save`, data: form_data , headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};
export const updateHolidayForm = async (data,id) => {
    let token = cookies.get("_am_token");
    var form_data = new FormData();
    for ( var key in data ) {
        form_data.append(key, data[key]);
    }
    // form_data.append('id', id);
    var res = await axios({ method: "POST", url: `${config.apiurl}holiday_update`, data: form_data , headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};
export const deleteHolidayForm = async (id) => {
    let token = cookies.get("_am_token");
    var form_data = new FormData();
    form_data.append('id', id);
    var res = await axios({ method: "POST", url: `${config.apiurl}holiday_delete`, data: form_data , headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};