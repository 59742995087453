import React, { useCallback, useEffect, useState } from 'react'
import { getIndividualEmployeeDetail, updateEmployeeDetail } from '../../api/employeeDetails'

import Layout from '../../components/layout/Layout'
import Radio from '../../components/radio/Radio'
import { Link, useParams } from "react-router-dom";
import Modals from '../../components/modal/Modals';
import spinner from '../../assets/images/spinner.gif'
import { generatePassword, taxCalculation } from '../../utility';
import { Modal } from '@mui/material';
import { XIcon } from '@heroicons/react/solid';
import { resetPassword } from '../../api/auth';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import DialogMessage from '../../components/DialogMessage';
let IS_REQUIRED = true
let val = [0, 0, 0]
function AddEmployeeDetails() {
    const { id, userType } = useParams()
    const [empCreateError, setEmpCreateError] = useState('')
    const [errorObject, setErrorObject] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [employeDetail, setEmployeDetail] = useState({})
    const [isShowingModal, setIsShowingModal] = useState(false)
    const [isConsultant, setIsConsultant] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false);
    const [eduationFile, setEducationFile] = useState([])
    const [regeneratePasswordModal, setRegeneratePasswordModal] = useState(false)
    const handleSetEmployeDetail = (e) => {
        const value = e.target.value
        if (e.target.name === 'cl_leave' || e.target.name === 'pl_leave' || e.target.name === 'dl_leave' || e.target.name === 'sl_leave' || e.target.name === 'ol_leave') {
            const id = e.target.getAttribute("data-id")
            val[id] = +value
            const sum = val.reduce(
                (previousValue, currentValue) => previousValue + currentValue,
                0,
            );
            console.log(val);
            let data = {
                ...employeDetail,
                [e.target.name]: +value,
                total_leave: sum
            }
            setEmployeDetail(data)
            return data
        }
        else {
            setEmployeDetail({
                ...employeDetail,
                [e.target.name]: value
            })
        }
        if (e.target.name === 'gst_number') {
            let data = { ...employeDetail }
            if (value === '' || !value || value === undefined) {
                data = { ...employeDetail, gst: 0, [e.target.name]: value }
                let calculatedData = taxCalculation(data)
                setEmployeDetail(calculatedData)
            } else {
                data = {
                    ...employeDetail,
                    [e.target.name]: value
                }
                setEmployeDetail(data)
            }
        }
        return {
            ...employeDetail,
            [e.target.name]: value,
        }
    }
    const handleSubmitEmployeDetail = (e) => {
        console.log(employeDetail)
        e.preventDefault()
        updateEmployeeDetail(employeDetail, eduationFile)
            .then((res) => {
                console.log(res)
                if (res.status === 200) {
                    setIsShowingModal(true)
                }
                setErrorObject(null)
            })
            .catch((err) => {
                console.log(err)
                if (err?.response?.data?.message === "Required Fields") {
                    setErrorObject(err?.response?.data?.data)
                }
                setEmpCreateError(err?.response?.data?.message)
            })
    }
    useEffect(() => {
        handleFetchIndividualData()
    }, [])

    const handleFetchIndividualData = () => {
        setIsLoading(true)
        getIndividualEmployeeDetail(id)
            .then((res) => {
                setIsLoading(false)
                setEmployeDetail(res.data.data)
                if (res.data.data.type_of_employee === 'consultant') {
                    val[0] = +res.data.data.dl_leave
                    val[1] = +res.data.data.ol_leave
                } else {
                    val[0] = +res.data.data.cl_leave
                    val[1] = +res.data.data.pl_leave
                    val[2] = +res.data.data.sl_leave
                }
            })
            .catch((err) => {
                console.log(err)
                setIsLoading(false)
            })
    }
    const handleTax = (e) => {
        let data = taxCalculation(handleSetEmployeDetail(e))
        setEmployeDetail(data)
        // console.log(data)
    }
    const [newPassword, setNewPassword] = useState('')
    const [copied, setCopied] = useState(false)
    const [isResettingPassword, setResettingPassword] = useState(false)
    const handleGeneratePassword = () => {
        var password = generatePassword({
            uppercase: true,
            lowercase: true,
            symbols: true,
            numbers: true
        })
        setCopied(false)
        setNewPassword(password)
    }

    const handleSetNewPassword = (e) => {
        setResettingPassword(true)
        e.preventDefault()
        setCopied(false)
        resetPassword(id, newPassword)
            .then((res) => {
                if (res.status === 200) {
                    setResettingPassword(false)
                    setRegeneratePasswordModal(false)
                }
            })
            .catch((err) => {
                setResettingPassword(false)
                alert("Something went wrong please try again later")
            })
    }
    return (
        <Layout>
            {isLoading ? <div className=' w-16 h-16 mx-auto my-16'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : <div className=" bg-[color:var(--color3)]">
                <div className=" container">
                    <div className=" px-4 py-4 text-start">
                        <Link to={"/employe-details"} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Back</Link>
                    </div>
                    <form action="" method="POST" onSubmit={handleSubmitEmployeDetail} >
                        <div className=" ">
                            <div className=" px-4">
                                <button type='button' onClick={() => setRegeneratePasswordModal(true)} className='px-5 py-2 rounded bg-gray-500 mt-9 text-white'>Regenerate Password</button>
                            </div>
                            <div className=" grid grid-cols-2 gap-4 p-4">
                                <h1 className='mt-4 text-2xl font-semibold'>Personal Details</h1><div></div>
                                <input type="hidden" value={employeDetail?.user_id} name='user_id' />
                                <div className="">
                                    <label htmlFor="first_name" className='label'>First Name:<span className='mandatory'>*</span></label>
                                    <input type="text" name='first_name' required={true} value={employeDetail?.first_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter first name ' />
                                </div>
                                <div className="">
                                    <label htmlFor="last_name" className='label'>Last Name:<span className='mandatory'>*</span></label>
                                    <input type="text" name='last_name' required={true} value={employeDetail?.last_name} onChange={handleSetEmployeDetail} className='input' placeholder='Enter last name ' />
                                </div>
                                <div className="">
                                    <label htmlFor="email" className='label'>Email:<span className='mandatory'>*</span></label>
                                    <input type="email" name='email' className='input' required={true} value={employeDetail?.email} onChange={handleSetEmployeDetail} placeholder='Email' />
                                </div>
                                <div className="">
                                    <label htmlFor="DOB" className='label'>Date Of Birth:<span className='mandatory'>*</span></label>
                                    <input type="date" name='DOB' className='input' required={true} value={employeDetail?.DOB} onChange={handleSetEmployeDetail} placeholder='Enter DOB ' />
                                </div>
                                <div className="">
                                    <p className='label'>Gender:<span className='mandatory'>*</span></p>
                                    <div className=" flex gap-x-4" >
                                        <Radio label={"Male"} name={"gender"} value={"male"} val={employeDetail?.gender} onChange={handleSetEmployeDetail} />
                                        <Radio label={"Female"} name={"gender"} value={"female"} val={employeDetail?.gender} onChange={handleSetEmployeDetail} />
                                    </div>
                                    {errorObject?.gender && <p className='text-xs text-red-600 mt-2'>{errorObject?.gender}</p>}
                                </div>
                                <br />
                                <div className="">
                                    <label htmlFor="mobile_number" className='label'>Mobile Number:<span className='mandatory'>*</span></label>
                                    <input type="number" required={true} name='mobile_number' className='input' value={employeDetail?.mobile_number} onChange={handleSetEmployeDetail} placeholder='Enter mobile number' />
                                    {errorObject?.mobile_number && <p className='text-xs text-red-600 mt-2'>{errorObject?.mobile_number}</p>}
                                </div>
                                <div className="">
                                    <label htmlFor="home_phone" className='label'>Alternate Number:</label>
                                    <input type="text" name='home_phone' className='input' value={employeDetail?.home_phone} onChange={handleSetEmployeDetail} placeholder='Alternate Number' />
                                </div>
                                <h1 className='mt-8 text-2xl font-semibold'>Address Details</h1><div></div>
                                <div className="">
                                    <label htmlFor="address_1" className='label'>Address Line 1:<span className='mandatory'>*</span></label>
                                    <input type="text" name='address_1' required={true} className='input' value={employeDetail?.address_1} onChange={handleSetEmployeDetail} placeholder='Enter address line 1 code' />
                                </div>
                                <div className="">
                                    <label htmlFor="address_2" className='label'>Address Line 2:</label>
                                    <input type="text" name='address_2' className='input' value={employeDetail?.address_2} onChange={handleSetEmployeDetail} placeholder='Enter address line 2 code' />
                                </div>
                                <div className="">
                                    <label htmlFor="pincode" className='label'>Pincode:<span className='mandatory'>*</span></label>
                                    <input type="text" name='pincode' required={true} className='input' value={employeDetail?.pincode} onChange={handleSetEmployeDetail} placeholder='Pincode' />
                                </div>
                                <div className="">
                                    <label htmlFor="city" className='label'>City:<span className='mandatory'>*</span></label>
                                    <input type="text" name='city' required={true} className='input' value={employeDetail?.city} onChange={handleSetEmployeDetail} placeholder='Enter city ' />
                                </div>
                                <h1 className='mt-4 text-2xl font-semibold'>Bank Details</h1><div></div>
                                <div className="">
                                    <label htmlFor="bank_name" className='label'>Bank Name:<span className='mandatory'>*</span></label>
                                    <input type="text" name='bank_name' required={true} className='input' value={employeDetail?.bank_name} onChange={handleSetEmployeDetail} placeholder='Bank name' />
                                </div>
                                <div className="">
                                    <label htmlFor="ifsc_code" className='label'>IFSC Code:<span className='mandatory'>*</span></label>
                                    <input required={true} type="text" name='ifsc_code' className='input' value={employeDetail?.ifsc_code} onChange={handleSetEmployeDetail} placeholder='IFSC Code' />
                                </div>
                                <div className="">
                                    <label htmlFor="account_number" className='label'>Account Number:<span className='mandatory'>*</span></label>
                                    <input required={true} type="number" name='account_number' className='input' value={employeDetail?.account_number} onChange={handleSetEmployeDetail} placeholder='Account number' />
                                </div>
                                {userType !== 'employee' && <div className="">
                                    <label htmlFor="gst_no" className='label'>GST Number:<span className='mandatory'>*</span></label>
                                    <input required={true} type="text" name='gst_number' className='input' value={employeDetail?.gst_number} onChange={handleSetEmployeDetail} placeholder='GST Number' />
                                </div>}
                                {/* <br /> */}
                                <div className="">
                                    <label htmlFor="pan_number" className='label'>Pan Number:<span className='mandatory'>*</span></label>
                                    <input required={true} type="text" name='pan_number' className='input' value={employeDetail?.pan_number} onChange={handleSetEmployeDetail} placeholder='Pan number' />
                                    {errorObject?.pan_number && <p className='text-xs text-red-600 mt-2'>{errorObject?.pan_number}</p>}
                                </div>
                                <div className="">
                                    <label htmlFor="aadhar" className='label'>Aadhar Number:<span className='mandatory'>*</span></label>
                                    <input required={true} type="text" name='aadhar_number' className='input' value={employeDetail?.aadhar_number} onChange={handleSetEmployeDetail} placeholder='Aadhar number' />
                                    {errorObject?.aadhar_number && <p className='text-xs text-red-600 mt-2'>{errorObject?.aadhar_number}</p>}
                                </div>
                                <div></div>
                                <h1 className='mt-4 text-2xl font-semibold'>Work Details</h1><div></div>
                                <div className="">
                                    <label htmlFor="qualification" className='label'>Educational Qualification:<span className='mandatory'>*</span></label>
                                    <input required={true} type="text" name='qualification' className='input' value={employeDetail?.qualification} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
                                </div>
                                {/* <div className=" invisible">
                                    <label htmlFor="education_certificates" className='label'>Education Certificates:</label>
                                    <input type="file" multiple name='education_certificates' className='input border-none' value={employeDetail?.education_certificates} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
                                </div> */}
                                <div className="">
                                    <label htmlFor="work_experience" className='label'>Work Experience :<span className='mandatory'>*</span></label>
                                    <input type="text" required={true} name='work_experience' className='input' value={employeDetail?.work_experience} onChange={handleSetEmployeDetail} placeholder='Enter your work experience' />
                                </div>
                                {/* <div className=" invisible">
                                    <label htmlFor="work_documents" className='label'>Work Experience Documents:</label>
                                    <input type="file" multiple name='work_documents' className='input border-none' value={employeDetail?.work_documents} onChange={handleSetEmployeDetail} placeholder='Enter your qualification' />
                                </div> */}
                                <div className="">
                                    <label htmlFor="designation" className='label'>Designation:<span className='mandatory'>*</span></label>
                                    <input type="text" required={true} name='designation' className='input' value={employeDetail?.designation} onChange={handleSetEmployeDetail} placeholder='Designation' />
                                </div>
                                <div className="">
                                    <label htmlFor="department" className='label'>Department:<span className='mandatory'>*</span></label>
                                    <input type="text" required={true} name='department' className='input' value={employeDetail?.department} onChange={handleSetEmployeDetail} placeholder='Department' />
                                </div>
                                <div className="">
                                    <label htmlFor="date_of_joining" className='label'>Date Of Joining:<span className='mandatory'>*</span></label>
                                    <input type="date" required={true} name='date_of_joining' className='input' value={employeDetail?.date_of_joining} onChange={handleSetEmployeDetail} placeholder='Date of joining' />
                                </div>
                                {/* <div className="">
                                    <label htmlFor="date_of_leaving" className='label'>Date Of Leaving:</label>
                                    <input type="date" name='date_of_leaving' className='input' value={employeDetail?.date_of_leaving} onChange={handleSetEmployeDetail} placeholder='Date of leaving' />
                                </div> */}
                            </div>

                            <div className=" grid grid-cols-2 gap-4 p-4">
                                <h1 className='mt-4 text-2xl font-semibold'>Salary Details</h1><div></div>
                                <div className="">
                                    <label htmlFor="salary" className='label'>Gross Salary / Consultant Fee:<span className='mandatory'>*</span></label>
                                    <input type="text" name='salary' required={true} className='input' value={employeDetail?.salary} onChange={handleTax} placeholder='Salary / Consultant Fee' />
                                </div>
                                {userType === 'consultant' ? <div className="">
                                    <label htmlFor="gst" className='label'>GST <small>{`(In Amount)`}</small></label>
                                    <input type="text" name='gst' className='input disabled:cursor-not-allowed' disabled={!employeDetail.gst_number} value={employeDetail?.gst} onChange={handleTax} placeholder='Enter gst' />
                                    <small className=' text-gray-500 font-semibold'>Please Enter GST Number to avail GST</small>
                                </div> : <div className="">
                                    <label htmlFor="professional_tax" className='label'>Professional Tax:</label>
                                    <input type="text" name='professional_tax' className='input' value={employeDetail?.professional_tax} onChange={handleTax} placeholder='Professional tax' />
                                </div>}
                                <div className="">
                                    <label htmlFor="tds" className='label'>TDS:</label>
                                    <input type="text" name='tds' className='input' readOnly={userType === 'employee' ? false : true} disabled={userType === 'employee' ? false : true} value={employeDetail?.tds} onChange={handleTax} placeholder='TDS' />
                                </div>
                                <div className="">
                                    <label htmlFor="g_salary" className='label'>Net Salary / Consultant Fee:</label>
                                    <input type="text" name='gross_salary' readOnly disabled className='input disabled:cursor-not-allowed' value={employeDetail?.gross_salary} onChange={handleTax} placeholder='Gross Salary / Consultant Fee' />
                                </div>
                            </div>
                            <br />
                            <div className=" grid grid-cols-2 gap-4 p-4 leaveAllot-section">
                                <h1 className='mt-4 text-2xl font-semibold'>Leave Details</h1><div></div>
                                {userType === 'employee' ? <>
                                    <div className="">
                                        <label htmlFor="pl_leave" className='label'>Privilege Leave:</label>
                                        <input type="text" name='pl_leave' data-id='1' id='pl_leave' className='input' value={employeDetail?.pl_leave} onChange={handleSetEmployeDetail} placeholder='Privilege Leave' />
                                    </div>
                                    <div className="">
                                        <label htmlFor="cl_leave" className='label'>Casual Leave:</label>
                                        <input type="text" name='cl_leave' data-id='0' id='cl_leave' className='input disabled:cursor-not-allowed' value={employeDetail?.cl_leave} onChange={handleSetEmployeDetail} placeholder='Casual leave' />
                                    </div>
                                </> :
                                    <>
                                        <div className="">
                                            <label htmlFor="ol_leave" className='label'>Other Leave:</label>
                                            <input type="text" name='ol_leave' data-id='1' id='ol_leave' className='input' value={employeDetail?.ol_leave} onChange={handleSetEmployeDetail} placeholder='Other Leave' />
                                        </div>
                                        <div className="">
                                            <label htmlFor="dl_leave" className='label'>Discretionary Leave:</label>
                                            <input type="text" name='dl_leave' data-id='0' id='dl_leave' className='input' value={employeDetail?.dl_leave} onChange={handleSetEmployeDetail} placeholder='Discretional Leave' />
                                        </div>
                                    </>
                                }
                                {userType === 'employee' && <div className="">
                                    <label htmlFor="sl_leave" className='label'>Sick Leave:</label>
                                    <input type="text" name='sl_leave' data-id='2' id='sl_leave' className='input disabled:cursor-not-allowed' disabled={isConsultant} value={employeDetail?.sl_leave} onChange={handleSetEmployeDetail} placeholder='Sick leave' />
                                </div>}
                                <div className="">
                                    <label htmlFor="total_leave" className='label'>Total Leave:<span className='mandatory'>*</span></label>
                                    <input type="text" name='total_leave' readOnly disabled required={true} className='input disabled:cursor-not-allowed' value={employeDetail?.total_leave} onChange={handleSetEmployeDetail} placeholder='Total leaves' />
                                    {errorObject?.total_leave && <p className='text-xs text-red-600 mt-2'>{errorObject?.total_leave}</p>}
                                </div>
                            </div>
                        </div>
                        <div className=" text-center my-8">
                            <button type='submit' className=' bg-[color:var(--color1)] hover:bg-[color:var(--color9)] text-white py-3 px-20 rounded-full'>Submit</button>
                        </div>
                    </form>
                </div>
            </div>}
            <Modals show={isShowingModal}>
                <div>
                    <p className=' roboto'>Your Data is Sucessfully Stored </p>
                    <div className=" mt-12">
                        <Link to={"/employe-details"} className='  bg-[color:var(--color1)] text-white px-5 py-2 rounded'>Ok</Link>
                    </div>
                </div>
            </Modals>
            <Modal open={regeneratePasswordModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
                <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
                    <div className='document-wrapper px-4 divide-y'>
                        <div className="document-head py-4">
                            <div className='flex items-center justify-between'>
                                <h3 className=' text-gray-700 text-3xl font-semibold'>Reset Password</h3>
                                <div className=' w-6 h-6 cursor-pointer' onClick={() => { setRegeneratePasswordModal(false); setCopied(false) }}>
                                    <XIcon className=' text-black w-full h-full' />
                                </div>
                            </div>
                        </div>
                        <div className="document-body py-4 max-h-96 overflow-auto">
                            <form onSubmit={handleSetNewPassword}>
                                <div className=' grid grid-cols-2 gap-4 items-end'>
                                    <div className="">
                                        <label htmlFor="gross_salary" className='label'>Password</label>
                                        <input type="text" name='password' readOnly disabled className='input disabled:cursor-not-allowed' placeholder='Password' value={newPassword} />
                                    </div>
                                    <CopyToClipboard onCopy={() => { setCopied(true) }} text={"New password: " + newPassword}>
                                        <button type='button' className='px-5 py-2 h-[2.6rem] w-max rounded bg-[color:var(--color9)] text-white mt-4'>{copied ? 'Copied' : 'Copy'} </button>
                                    </CopyToClipboard>
                                </div>
                                <div className="">
                                    <label htmlFor="gross_salary" className='label'></label>
                                    {newPassword ? ' ' : <button type='button' onClick={handleGeneratePassword} className='px-5 py-2 rounded bg-gray-500 mt-9 text-white'>Generate Password</button>}
                                </div>
                                {isResettingPassword ? <div className='w-6 h-6 mx-0 mt-5'><img src={spinner} alt="spinner" className='w-full h-full' /></div> : newPassword && <div className='mt-5'>
                                    <button className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Save</button>
                                </div>}
                            </form>
                        </div>
                    </div>
                </div>
            </Modal>
            <DialogMessage open={empCreateError} type={"Error"} message={empCreateError === "Required Fields" ? "Some fields are missing" : empCreateError} handleClose={() => setEmpCreateError('')} />
        </Layout>
    )
}

export default AddEmployeeDetails