import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function ZoomImage({ children }) {
    return (
        <Zoom>
            {children}
        </Zoom>
    )
}

export default ZoomImage