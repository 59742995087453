import axios from "axios";

import Cookies from "universal-cookie";
import { config } from "../config";
const cookies = new Cookies();

export const getEmployeeDetail = async () => {
    let token = cookies.get("_am_token");
    var res = await axios({ method: "GET", url: `${config.apiurl}employeelist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res

};
export const postEmployeeDetails = async (data, educationFile, workDoc) => {
    var form_data = new FormData();
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    if (educationFile.length > 0) {
        for (let i = 0; i < educationFile.target.files.length; i++) {
            form_data.append('education_certificates[]', educationFile.target.files[i]);
        }
    }
    if (workDoc.length > 0) {
        for (let j = 0; j < workDoc.target.files.length; j++) {
            form_data.append('work_documents[]', workDoc.target.files[j]);
        }
    }
    let token = cookies.get("_am_token");
    var res = await axios({ method: "POST", url: `${config.apiurl}employeedetails`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
};
export const getIndividualEmployeeDetail = async (id) => {
    let token = cookies.get("_am_token");
    let fd = new FormData()
    fd.append('id',id)
    var res = await axios({ method: "POST", url: `${config.apiurl}employeedetails_edit`, data: fd, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};
export const updateEmployeeDetail = async (data) => {
    let fd = new FormData()
    for (var key in data) {
        fd.append(key, data[key]);
    }
    let token = cookies.get("_am_token");
    var res = await axios({ method: "POST", url: `${config.apiurl}employeedetails_update`, data: fd, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};
export const postEmployeeDetailsDelete = async (id) => {
    let token = cookies.get("_am_token");
    var fd = new FormData();
    fd.append('id', id);
    var res = await axios({ method: "POST", url: `${config.apiurl}employeedetails_delete`, data: fd, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
};

export const getLeaveAppliactionList = async () => {
    let token = cookies.get("_am_token");
    var res = await axios({ method: "GET", url: `${config.apiurl}leaveapplicationlist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}


export const getUserDocumentsList = async (id) => {
    var form_data = new FormData();
    let token = cookies.get("_am_token");
    form_data.append('id', id);
    var res = await axios({ method: "POST", url: `${config.apiurl}uploadfilelist`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}

export const userDocumentAdd = async (data) => {
    const { userId, file, docType } = data
    var form_data = new FormData();
    let token = cookies.get("_am_token");
    form_data.append('user_id', userId);
    form_data.append('document_type', docType);
    if (file.length > 0) {
        for (let i = 0; i < file.length; i++) {
            form_data.append('document[]', file[0]);
        }
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}uploadfileadd`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}
export const userDocumentRemove = async (id) => {
    var form_data = new FormData();
    let token = cookies.get("_am_token");
    form_data.append('media_id', id);
    var res = await axios({ method: "POST", url: `${config.apiurl}uploadfiledelete`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}

export const getAppoinmentLetterList = async () => {
    let token = cookies.get("_am_token");
    var res = await axios({ method: "GET", url: `${config.apiurl}appointmentletterlist`, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}


export const setAppoinmentLetter = async (data) => {
    let token = cookies.get("_am_token");
    var form_data = new FormData();
    form_data.append('user_id', data.id);
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}appointmentletterpdf`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return await res
}
export const setConfirmationLetter = async (data) => {
    let token = cookies.get("_am_token");
    var form_data = new FormData();
    form_data.append('user_id', data.id);
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}confirmationletterpdf`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
}

export const getSalaryList = async (data) => {
    let token = cookies.get("_am_token");
    var form_data = new FormData();
    for (var key in data) {
        form_data.append(key, data[key]);
    }
    var res = await axios({ method: "POST", url: `${config.apiurl}getMemberPayouts`, data: form_data, headers: { Authorization: token ? `Bearer ${token}` : '', } })
    return res
}



