// "https://hrmsapi.renderly.in/"
export const ENVIONMENT = 'development' //production | development
export const config = {
    token: 'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJpYXQiOjE2NTg3NTAwMzUsIm5iZiI6MTY1ODc1MDA0NSwiZXhwIjoxNjU5NjE0MDM1LCJ1c2VybmFtZSI6ImFkbWluaXN0cmF0b3IiLCJpZCI6dHJ1ZSwiaXNzIjoiVGVhbU5nYWdlIiwiYXVkIjoiVGVhbU5nYWdlIn0.9iciZeKjkEIZRB7Oe0UEMZM46O_2fOaRki7LbnmTmhw',
    role: 'admin',
    role: 'member',
    apiurl: "https://sapi-hrms.renderly.in/",
    // apiurl: "http://192.168.1.14/"
    // frontEndUrl: "http://localhost:3000/",
    frontEndUrl: 'https://stage-hrms.renderly.in/'
}

export const TOKEN = "_am_token"

export const TAX = 175;
export const ABOVE_LIMIT_TAX = 200;
export const EXEED_MONTH_LIMIT = 300;
export const TDS = 10;
export const PERCENTILE = 100;
export const GST = "";

export const MIN_LEAVE_DATE_FOR_PL = 15;
export const MIN_LEAVE_DATE_FOR_CL = 5;

export const DISCRETIONAL_LEAVE_YEAR_IGNORE = 2022 

