import { EyeIcon, PencilAltIcon } from "@heroicons/react/solid";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { getEmployeeDetail } from "../../api/employeeDetails";
import Layout from "../../components/layout/Layout"
import usePut from "../../hooks/usePut";
import { toast } from "react-hot-toast";
import spinner from '../../assets/images/spinner.gif'
const Attendance = () => {
    const [isLoading, setIsLoading] = useState(true)
    const [employeeData, setemployeeData] = useState([]);

    const {handleAuth} = usePut({
        url:"excelUpload",
        onSuccess(data){
            toast.success("File Uploaded Successfully",{
                position:"top-right",
                duration:3000
            })
            setTimeout(() => {
                window.location.reload()
            },[2000])
        },
        onError(err){
            toast.error("Oops! Something Went Wrong",{
                position:"top-right",
                duration:3000
            })
        }
    })

    useEffect(() => {
        getEmployeeList()
    }, [])
    const getEmployeeList = () => {
        setIsLoading(true)
        getEmployeeDetail()
            .then((res) => {
                if (res.status === 200) {
                    setIsLoading(false)
                    setemployeeData(res.data)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                alert('Something went wrong, Please reload')
                console.error(err)
            })
    }
    const onFileChange = (e) => { 
        let excelSheet = {
            excel_file: e.target.files[0]
        }
        // console.log(excelSheet);
        handleAuth.mutate(excelSheet)
    }
    return <Layout>
        {employeeData.length <= 0 ? <div className='text-center'><p className=' text-gray-700 text-3xl'>No Users To Show</p></div> :
            <div class="max-w-full overflow-x-auto px-4">
                {handleAuth.isLoading && <div className="fixed w-full h-full top-0 left-0 bg-[#ffffff7d]"> <img src={spinner} alt="" className="w-[10rem] h-[10rem] absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2" /></div>}
                <div className="flex flex-row justify-end items-center">
                    <label for="btn" className="bg-[color:var(--color1)] text-white rounded px-4 py-4 mb-2  font-medium cursor-pointer">
                        <input type="file" name="excel_file" id="btn" accept=".xlsx, .xls" hidden onChange={onFileChange} />
                        Upload xlsx
                    </label>
                </div>
                <table class="table-auto w-full">
                    <thead>
                        <tr class=" bg-[color:var(--color1)] text-center">
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Name</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Email</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">User Type</th>
                            <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Attendance</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            employeeData?.map((data, index) => {
                                const { employee_name, email, id, type_of_employee } = data
                                return <tr key={index}>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{email}</td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">{type_of_employee}</td>
                                    <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                        <div className='flex items-center justify-center w-full'>
                                            <Link to={`/view-attendance/${id}`} className='bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center'>
                                                <EyeIcon className='w-6 h-6' />
                                                <span>View</span>
                                            </Link>
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                    </tbody>
                </table>
            </div>}
    </Layout>
}

export default Attendance