import React, { useEffect, useRef, useState } from 'react'
import { getEmployeeDetail, getUserDocumentsList, postEmployeeDetailsDelete, userDocumentAdd, userDocumentRemove } from '../../api/employeeDetails'
import { Link } from "react-router-dom";
import Layout from '../../components/layout/Layout'
import { EyeIcon, PencilAltIcon, TrashIcon, XCircleIcon, XIcon } from '@heroicons/react/solid';
import Modal from '@mui/material/Modal';
import Modals from '../../components/modal/Modals';
import spinner from '../../assets/images/spinner.gif'
import ZoomImage from '../../components/ZoomImage';
import { downloadUserReport } from '../../api'


function EmployeeDetails() {
   const eduFileRef = useRef(null)
   const [isLoading,setIsLoading] = useState(true)
   const [employeeData, setemployeeData] = useState([])
   const [isConfirmDeleteModal, setIsConfirmDeleteModal] = useState(false)
   const [employeeId, setEmployeeId] = useState('')
   const [isOpenDocumentModal, setIsOpenDocumentModal] = useState(false)
   const [documents, setDocuments] = useState([])
   const handleDelete = () => {
      postEmployeeDetailsDelete(employeeId)
         .then((res) => {
            if (res.status === 200) {
               setIsConfirmDeleteModal(false)
               getEmployeeList()
            }
         })
         .catch(err => {
            console.error(err)
         })

   }
   useEffect(() => {
      getEmployeeList()
   }, [])

   const [removeDocumentConfirmModal, setRemoveDocumentConfirmModal] = useState(false)
   const [userId, setUserId] = useState('')
   const [doumentId, setDocumentId] = useState('')
   const [docType,setDocType] = useState('')
   const [isUploadingFile,setIsuploadingFile] = useState(false)
   const getEmployeeList = () => {
      setIsLoading(true)
      getEmployeeDetail()
         .then((res) => {
            if(res.status === 200){
               setIsLoading(false)
               setemployeeData(res.data)
            }
         })
         .catch((err) =>{
            setIsLoading(false)
            alert('Something went wrong, Please reload')
            console.error(err)
         })
   }
   
   const handleFetchUserDocuments = (id) => {
      setUserId(id)
      getUserDocumentsList(id)
         .then((res) => {
            if (res.status === 200) {
               setDocuments(res.data.data)
               setIsOpenDocumentModal(true)
            }
         })
         .catch((err) => {
            console.log(err)
         })
   }
   const handleRemoveDocumentConfirmModal = (id) => {
      setDocumentId(id)
      setIsOpenDocumentModal(false)
      setRemoveDocumentConfirmModal(true)
   }

   const handleDeleteDocument = () => {
      userDocumentRemove(doumentId)
         .then((res) => {
            if (res.status === 200) {
               setRemoveDocumentConfirmModal(false)
               setIsOpenDocumentModal(true)
               handleFetchUserDocuments(userId)
            }
            console.log(res)
         })
         .catch((err) => {
            console.log(err)
         })
   }

   const handleAddEduDoc = () => {
      if(!docType || docType === '' || docType === undefined) return alert('Please select document type first')
      eduFileRef.current.click()
   }
   const handleFileUpload = (e) => {
      setIsuploadingFile(true)
      let file = e.target.files
      userDocumentAdd({userId,file,docType})
      .then((res) => {
         if(res.status === 200){
            setIsuploadingFile(false)
            setIsOpenDocumentModal(true)
            handleFetchUserDocuments(userId)
         }
      })
      .catch((err) => {
         setIsuploadingFile(false)
         alert('Something went wrong while uploading please try again')
      })
   }
   const handleDownloadReport = () => {
      downloadUserReport()
          .then((res) => {
              // console.log(res)
              // create file link in browser's memory
              const href = URL.createObjectURL(res.data);
              
              let fileName = `User_Report.pdf`
              // create "a" HTLM element with href to file & click
              const link = document.createElement('a');
              link.href = href;
              link.setAttribute('download', fileName); //or any other extension
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
          })
          .catch(() => { })
  }
   return (
      <Layout>
         {isLoading ? <div className=' w-16 h-16 mx-auto mt-8'><img src={spinner} alt="spinner" className='w-full h-full'/></div> : <div className="mb-12">
            <div className=" container">
               <div className="space-x-4 px-4 py-4 text-end">
                  <Link to={"/add-employe-details"} className=" bg-[color:var(--color1)] inline-block text-white rounded px-4 py-4">Add User Details</Link>
                  <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-4">Download Report</button>
               </div>
            </div>
            {employeeData.length <= 0 ? <div className='text-center'><p className=' text-gray-700 text-3xl'>No Users To Show</p></div> : <div class="max-w-full overflow-x-auto px-4 mt-8">
               <table class="table-auto w-full">
                  <thead>
                     <tr class=" bg-[color:var(--color1)] text-center">
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Name</th>
                        {/* <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Mobile Number</th> */}
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Email</th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">User Type</th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Documents</th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Leaves</th>
                        <th class="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4border-r border-transparent">Modify</th>
                     </tr>
                  </thead>
                  <tbody>
                     {
                        employeeData?.map((data, index) => {
                           const { employee_name, phone, email, id, type_of_employee } = data
                           return <tr key={index}>
                              <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{employee_name}</td>
                              {/* <td class=" text-center text-dark font-medium text-base py-3 px-2 bg-white border-b border-[#E8E8E8]">{phone}</td> */}
                              <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{email}</td>
                              <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">{type_of_employee}</td>
                              <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                 <div className='flex items-center justify-center w-full'>
                                    <button type='button' onClick={() => handleFetchUserDocuments(id)} className='bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center'><EyeIcon className='w-6 h-6' /> <span>View</span></button>
                                 </div>
                              </td>
                              <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                 <div className='flex items-center justify-center w-full'>
                                    <Link to={`/leaves/${id}`} className='bg-[color:var(--color1)] text-white rounded px-4 py-2 flex items-center space-x-2 justify-center'><EyeIcon className='w-6 h-6' /> <span>View</span></Link>
                                 </div>
                              </td>
                              <td class="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-r border-[#E8E8E8]">
                                 <div className=" flex justify-center space-x-1">
                                     <Link to={`/edit-employe-details/${type_of_employee}/${id}`} className=" ">
                                       <PencilAltIcon className=' text-gray-600 w-6 hover:text-blue-600' />
                                    </Link>
                                    <div className="" >
                                       <TrashIcon className=' text-gray-600 w-6 hover:text-orange-600 cursor-pointer' onClick={() => { setIsConfirmDeleteModal(true); setEmployeeId(id) }} />
                                    </div>
                                 </div>
                              </td>
                           </tr>
                        })
                     }
                  </tbody>
               </table>
            </div>}
         </div>}
         <Modals show={isConfirmDeleteModal}>
            <div>
               <p className=' roboto text-center font-bold text-2xl text-gray-700'>Are you sure you want to delete?</p>
               <div className=" mt-12 space-x-3 text-center ">
                  <button className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={handleDelete}>Yes</button>
                  <button className='px-4 py-2 rounded-md font-semibold  border' onClick={() => setIsConfirmDeleteModal(false)}>Cancel</button>
               </div>
            </div>
         </Modals>
         <Modal open={isOpenDocumentModal} aria-labelledby="transition-modal-title" aria-describedby="transition-modal-description">
            <div className='w-[60%] bg-white absolute left-1/2 -translate-x-1/2 top-[20%]'>
               <div className='document-wrapper px-4 divide-y'>
                  <div className="document-head py-4">
                     <div className='flex items-center justify-between'>
                        <h3 className=' text-gray-700 text-3xl font-semibold'>Documents</h3>
                        <div className=' w-6 h-6 cursor-pointer' onClick={() => setIsOpenDocumentModal(false)}>
                           <XIcon className=' text-black w-full h-full' />
                        </div>
                     </div>
                  </div>
                  <div className="document-body py-4 max-h-96 overflow-auto">
                     {documents?.length <= 0 ? <div className='text-gray-700 my-5 text-center text-2xl'><h3>No Documents To Show</h3></div> : <div className='grid grid-cols-4 gap-3'>
                        {documents?.map((document, idx) => {  
                           const { file_name, media_id,media_type,document_media_type } = document
                           return <>
                           {
                              document_media_type === 'pdf' ? <a href={media_type} target="_blank" rel="noopener noreferrer" className=' text-center py-2 px-2 rounded bg-gray-400 text-gray-700'>View PDF</a> : 
                              // <ZoomImage key={idx} className='h-full'>
                              //    <img src={media_type} alt="dummy" className='border-2 border-black w-full h-full' />
                              // </ZoomImage>
                               <div key={idx} className='w-full h-full border-2 border-black relative'>
                               <div className='w-6 h-6 absolute cursor-pointer right-2 top-2 z-20' onClick={() => handleRemoveDocumentConfirmModal(media_id)}><XCircleIcon className=' w-full h-full' /></div>
                               <ZoomImage><img src={media_type} alt="documents" className='w-full h-full object-cover' /></ZoomImage>
                            </div>
                           }
                        </>
                          
                        })}
                     </div>}
                  </div>
                  <div className="document-foot space-x-4 py-4">
                  {isUploadingFile ? <div className=' w-8 h-8 mx-auto'><img src={spinner} alt="spinner" className='w-full h-full'/></div> : <div className="grid grid-cols-4 gap-3">
                        <input ref={eduFileRef} type="file" className='hidden' multiple onChange={handleFileUpload}/>
                        <select name="documentType" className='input' onChange={(e) => setDocType(e.target.value)}>
                           <option value="">-- Select Document Type --</option>
                           <option value="EDUCTIONAL">Education</option>
                           <option value="WORK">Work</option>
                        </select>
                        <button className=' py-2 px-4 rounded font-semibold bg-[color:var(--color1)] text-white' onClick={handleAddEduDoc}>Add Document</button>
                        <button className=' py-2 px-4 rounded font-semibold border border-[color:var(--color1)]' onClick={() => setIsOpenDocumentModal(false)}>Cancel</button>
                     </div>}
                  </div>
               </div>
            </div>
         </Modal>
         <Modal show={removeDocumentConfirmModal}>
            <div>
               <p className=' roboto text-center font-bold text-2xl text-gray-700'>Are you sure you want to delete this document?</p>
               <div className=" mt-12 space-x-3 text-center ">
                  <button className='px-4 py-2 rounded-md font-semibold bg-red-200 text-red-700 border-red-800' onClick={handleDeleteDocument}>Yes</button>
                  <button className='px-4 py-2 rounded-md font-semibold  border' onClick={() => { setRemoveDocumentConfirmModal(false); setIsOpenDocumentModal(true) }}>Cancel</button>
               </div>
            </div>
         </Modal>
      </Layout>
   )
}

export default EmployeeDetails