import React, { useState } from 'react'
import Cookies from 'universal-cookie';
import { Link, Navigate, useNavigate } from 'react-router-dom'
import { loginUser, registerCompany } from '../../api/auth';
import { COMPANY_DETAILS } from './companyInputs'
import ambrosianLogo from "../../assets/images/renderly_blue.png"
import spinner from '../../assets/images/spinner.gif'
import { generatePassword } from '../../utility';
import { Tooltip } from '@mui/material';
import { PasswordInput } from '../../components/PasswordInput/PasswordInput';
import CopyToClipboard from 'react-copy-to-clipboard';
import Modals from '../../components/modal/Modals';

const cookies = new Cookies

const InitialCompanyData = {
    company_name: '',
    industry: '',
    company_password: '',
    company_email: '',
    phone_number: '',
    strength: '',
    hash: ''
}

const Register = () => {
    const [userData, setuserData] = useState(InitialCompanyData)
    const [isShowingModal, setIsShowingModal] = useState(false)
    const [error, setError] = useState(false)
    let token = cookies.get("_am_token");
    const [isLoading, setIsLoading] = useState(false)
    let navigate = useNavigate()

    const handleSetUserData = (e) => {
        let value = e.target.value
        setuserData({
            ...userData,
            [e.target.name]: value
        })
    }
    const Submit = (e) => {
        setIsLoading(true)
        e.preventDefault();
        let data = {}
        Object.keys(InitialCompanyData).forEach((item) => {
            data[item] = userData[item]
        })
        registerCompany(userData)
            .then((res) => {
                if (res.status === 200) {
                    navigate("/login", { replace: true });
                    setIsLoading(false)
                }
            })
            .catch((err) => {
                setIsLoading(false)
                setError(true)
            })
    }

    const handleGeneratePassword = () => {
        var hash = generatePassword({
            uppercase: true,
            lowercase: true,
            symbols: true,
            numbers: true,
            passLen: 20
        })
        let data = {
            ...userData,
            hash,
        }
        setuserData(data)
        return
    }


    if (token) return <Navigate to="/" replace />
    return (
        <>
            <div className="flex flex-col items-center justify-center min-h-screen py-2 bg-gray-100">
                <div className="flex flex-col items-center justify-center w-full flex-1 px-10 ">
                    <div className="bg-white rounded-2xl shadow-2xl flex w-full">
                        <div className="w-full py-20 px-9">
                            <p className=" text-3xl font-semibold mb-4 roboto">Register Company</p>
                            <form onSubmit={Submit} className=" space-y-4">
                                <div className=" grid grid-cols-2 gap-4 p-4 border-b-2">
                                    {
                                        COMPANY_DETAILS.map((item) => {
                                            return (
                                                <div className="" key={item['name']}>
                                                    <div className='flex flex-row items-center'>
                                                        <label htmlFor="first_name" className='label'>{item.label}</label>
                                                        {
                                                            item['toolTipMessage'] && (
                                                                <Tooltip title={item['toolTipMessage']} arrow>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-3 mb-[0.5rem] hover:cursor-pointer">
                                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                                    </svg>
                                                                </Tooltip>
                                                            )
                                                        }
                                                    </div>
                                                    {
                                                        item.type === "password" ? (
                                                            <PasswordInput value={userData[item['name']]} onChange={handleSetUserData} {...item} />
                                                        ) : (
                                                            <input required value={userData[item['name']]} onChange={handleSetUserData} className='input' {...item} />
                                                        )
                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                    <div className=' grid grid-cols-2 gap-4 items-end'>
                                        <div className="h-max flex flex-1 flex-col">
                                            <div className='flex flex-row items-center'>
                                                <label htmlFor="gross_salary" className='label'>Security Key</label>
                                                <Tooltip title="This key would be used to encrypt your data" arrow>
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-4 h-4 ml-3 mb-[0.5rem] hover:cursor-pointer">
                                                        <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z" />
                                                    </svg>
                                                </Tooltip>
                                            </div>
                                            <input type="text" name='password' readOnly disabled className='input disabled:cursor-not-allowed' placeholder='Password' value={userData.hash} />
                                        </div>
                                        <div className="w-max">
                                            <label htmlFor="gross_salary" className='label'></label>
                                            <button type='button' onClick={handleGeneratePassword} className='px-5 py-2 rounded bg-[color:var(--color9)] text-white'>Generate Key</button>
                                            <CopyToClipboard onCopy={() => { userData.hash && setIsShowingModal(true) }} text={userData.hash}>
                                                <button type='button' className='px-5 py-2 rounded bg-[color:var(--color9)] text-white ml-2'>Copy</button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                                {isLoading ? <div className=' w-8 h-8 mx-auto'>
                                    <img src={spinner} alt="spinner" className='w-full h-full' />
                                </div> : <div className="button">
                                    <button type="submit" className=" w-full py-2 bg-[color:var(--color1)] text-white hover:bg-[color:var(--color9)] rounded roboto">Register</button>
                                </div>}
                                <p className='text-sm'>Already have an account? <Link to={"/login"} className='text-blue-600 underline'>Login</Link></p>
                            </form>
                        </div>
                        <div className="w-2/5  text-white rounded-r-2xl rounded-br-2xl flex flex-col justify-center items-center text-center ">
                            <p className=" roboto text-sl font-semibold text-[color:var(--color1)]">Welcome To <span className="font-extrabold">Renderly HRMS</span></p>
                            <div className=" flex justify-center mt-8">
                                <img src={ambrosianLogo} alt="logo" className=' h-40' />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modals show={isShowingModal}>
                <div>
                    <p className=' roboto'>Your Data is successfully copied</p>
                    <div className=" mt-12">
                        <p onClick={() => { setIsShowingModal(false) }} className='w-max  bg-[color:var(--color1)] text-white px-5 py-2 rounded hover:cursor-pointer'>Ok</p>
                    </div>
                </div>
            </Modals>
        </>
    )
}

export default Register