import { XIcon, ArrowLeftIcon } from '@heroicons/react/solid'
import { Modal } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { getSalaryList } from '../../api/employeeDetails'
import Layout from '../../components/layout/Layout'
import DatePicker from "react-datepicker";
import spinner from '../../assets/images/spinner.gif';
import Calendar from '../../components/Dates/Calendar'
import { downloadReport } from '../../api'
import { getAttendanceData } from '../../api/leaveApplicationForm'
import { Link } from 'react-router-dom'

const getDateMonthString = (date) => {
    let tempDate = new Date(date)
    let month = tempDate.getMonth() + 1
    return tempDate.getFullYear() + "-" + month
}

function Salary() {
    let initialData = {
        month_and_year: new Date(),
        prev_month_and_year: new Date()
    }

    const [filterData, setFilterData] = useState(initialData)
    const [isFetching, setIsFetching] = useState(false)
    const [salaryList, setSalaryList] = useState([])

    const handleDate = (value, name) => {
        let data = {
            ...filterData,
            [name]: value,
        }
        setFilterData(data)
        handleCalculateSalary(data)
    }
    const handleCalculateSalary = (data) => {
        const temp = {
            ...data,
            month_year: getDateMonthString(data['month_and_year'])
        }
        setIsFetching(true)
        getSalaryList(temp)
            .then((res) => {
                if (res.status === 200) {
                    setSalaryList(res?.data.data)
                    setIsFetching(false)
                }
            })
            .catch(err => {
                alert('Something went wrong!')
                setIsFetching(false)
            })
    }

    const handleDownloadReport = () => {
        const temp = {
            ...filterData,
            month_year: getDateMonthString(filterData['month_and_year'])
        }
        downloadReport(temp)
            .then((res) => {
                // console.log(res)
                // create file link in browser's memory
                const href = URL.createObjectURL(res.data);
                const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                const d = new Date();
                let fileName = `Salary_Report_${months[d.getMonth()]}_${d.getFullYear()}.xlsx`
                // create "a" HTLM element with href to file & click
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(() => { })
    }

    useEffect(() => {
        handleCalculateSalary(filterData)
    }, [])

    return (
        <Layout>
            <div className='px-4'>
                <form >
                    <div className='flex space-x-4 items-end justify-between'>
                        <div className='min-w-[10rem]  basis-[25%]'>
                            <label className=' roboto label'>Select Month/Year</label>
                            <DatePicker required name='month_and_year' autoComplete='false' className='input' dateFormat="MM/yyyy" showMonthYearPicker selected={filterData?.month_and_year} onChange={(value) => handleDate(value, 'month_and_year')} />
                        </div>
                        {salaryList?.length > 0 &&
                            <div className=''>
                                <button type='button' onClick={handleDownloadReport} className=" bg-[color:var(--color1)] text-white rounded px-4 py-2">Download Report</button>
                            </div>}
                    </div>
                </form>
            </div>
            {isFetching && <div className=' w-10 h-10 mx-auto my-8'>
                <img src={spinner} alt="spinner" className='w-full h-full' />
            </div>}
            {salaryList?.length > 0 &&
                <div className="max-w-full overflow-x-auto px-4 mt-8">
                    <table className="table-auto w-full">
                        <thead>
                            <tr className=" bg-[color:var(--color1)] text-center">
                                <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4 border-l border-transparent">Sr.No</th>
                                <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Name</th>
                                <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">User Type</th>
                                <th className="w-1/6 min-w-[160px] text-lg font-semibold text-white py-3 lg:py-3 px-3 lg:px-4">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {salaryList?.map((data, idx) => {
                                const { employee_name, status, user_type, id } = data
                                return <tr key={idx}>
                                    <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{idx + 1}</td>
                                    <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-l border-[#E8E8E8]">{employee_name}</td>
                                    <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8] capitalize">{user_type}</td>
                                    {

                                    }
                                    <td className="text-center text-dark font-medium text-base py-3 px-2 bg-[#F3F6FF] border-b border-[#E8E8E8]">
                                        {
                                            status === "left" ? (
                                                <div className='flex items-center justify-center'>
                                                    Left
                                                </div>
                                            ) : status === 'joining_date_diff' && (
                                                <div className='flex items-center justify-center'>
                                                    Joining Date Diff
                                                </div>
                                            )
                                        }
                                        {
                                            status === "not_generated" ? (
                                                <Link
                                                    to={'/salary/logs?id=' + id + "&date=" + filterData['month_and_year'] + "&prev_date=" + filterData['prev_month_and_year'] + "&status=not_generated" + "&emp=" + user_type}
                                                    className='px-4 py-2 rounded bg-[color:var(--color1)] text-white'
                                                >
                                                    Generate
                                                </Link>
                                            ) : status === 'generated' && <>
                                                <Link
                                                    to={'/salary/logs?id=' + id + "&date=" + filterData['month_and_year'] + "&prev_date=" + filterData['prev_month_and_year'] + "&status=generated" + "&emp=" + user_type}
                                                    className='px-4 py-2 rounded bg-[color:var(--color1)] text-white'
                                                >
                                                    View
                                                </Link>
                                            </>
                                        }
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                </div>
            }
        </Layout>
    )
}

export default Salary