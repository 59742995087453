import React, { useEffect, useState } from 'react'
import { BriefcaseIcon, CalendarIcon, CashIcon, ClipboardIcon, DocumentTextIcon, PresentationChartBarIcon } from '@heroicons/react/solid'
import { NavLink } from "react-router-dom";
import Cookies from 'universal-cookie';

const cookies = new Cookies

function Sidebar({ show }) {
  const [role, setRole] = useState('')
  useEffect(() => {
    setRole(cookies.get("_am_role"))
  }, [])
  return (
    <>

      <div className={`bg-[color:var(--color1)] border-r border-[color:var(--color1)] w-64 h-screen p-4 fixed ${show ? 'active' : ''}`}>
        <div >
          <div>
            <p className=' text-center text-3xl text-white font-bold mb-10 roboto mt-4'><NavLink to={"/"}>Renderly</NavLink></p>
          </div>
          <div className='divide-slate-400 divide-y-[1px]'>
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/employe-details'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <BriefcaseIcon className='w-6' />
                <span>User Details</span>
              </NavLink>
            </div>}
            {role === 'employee' || role === 'consultant' ? <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/leave-application'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <DocumentTextIcon className=' w-6' />
                <span>Leave Application</span>
              </NavLink>
            </div> : null}
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/appointment'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <ClipboardIcon className='w-6' />
                <span>Appointment Letter</span>
              </NavLink>
            </div>}
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/leave-applied'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <ClipboardIcon className='w-6' />
                <span>Leaves Applied</span>
              </NavLink>
            </div>}
            {(role === 'employee' || role === 'consultant') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/leaves'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <ClipboardIcon className='w-6' />
                <span>Leaves Applied</span>
              </NavLink>
            </div>}
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/salary'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <CashIcon className='w-6' />
                <span>Salary</span>
              </NavLink>
            </div>}
            {(role === 'company' || role === 'hr') && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/public-holidays'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <CalendarIcon className='w-6' />
                <span>Public Holidays</span>
              </NavLink>
            </div>}
            {role === 'company' && <div className=" text-white text-lg roboto text-center py-2">
              <NavLink to={'/attendance'} className={({ isActive }) => `${isActive ? 'bg-[color:var(--color10)] ' : 'hover:bg-[color:var(--color10)]'} flex items-center px-2 py-2 rounded space-x-3 `}>
                <PresentationChartBarIcon className='w-6' />
                <span>Attendance</span>
              </NavLink>
            </div>}
          </div>
        </div>
      </div>
    </>
  )
}

export default Sidebar